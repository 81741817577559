import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import Transactions from '../components/Transactions'

const TransactionsPage = () => {
    return (
        <>
            <Navigation />
            <Transactions />
        </>
    )
}

export default TransactionsPage
