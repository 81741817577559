import React, { useState, useEffect } from 'react'
import {
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from 'react-i18next'
import InfoSite from '../InfoSite'
import apiRequest from "../../axios"
import { LoadingIcon } from '../custom/LoadingIcon'
import { ErrorIconWrapper, RightSide, SuccessIconWrapper } from '../Forms/FormElements';
import { SuccessMessage } from '../custom/SuccessMessage';
import LineIcon from "react-lineicons"
import { Button } from 'react-bootstrap';
import { ErrorMessage } from '../custom/ErrorMessage';

const RegisterVerify = () => {
    let { verifyToken } = useParams()
    const { t } = useTranslation()
    const backgroundImg = require("../../images/products/kvm_server.jpg").default

    const [verifyTitle, setVerifyTitle] = useState("register.verify.tryToVerify.title")
    const [verifying, setVerifying] = useState(true)
    const [verified, setVerified] = useState(false)

    useEffect(() => {
        apiRequest("post", "/auth/verifyEmail?token=" + verifyToken, {}, () => {

        }, () => {
            setVerified(true)
            setVerifying(false)
            setVerifyTitle("register.verify.verified.title")
        }, () => {
            setVerifying(false)
            setVerifyTitle("register.verify.errors.errorTitle")
        })
    }, []);

    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title={verifyTitle} content={
                verifying ? <LoadingIcon show /> : (
                    verified ?
                        <>
                            <SuccessIconWrapper>
                                <LineIcon name="checkmark-circle" />
                            </SuccessIconWrapper>
                            <SuccessMessage>{t("register.verify.verified.text")}</SuccessMessage>
                            <RightSide>
                                <Link to="/login">
                                    <Button>{t("login.login")}</Button>
                                </Link>
                            </RightSide>
                        </>
                        :
                        <>
                            <ErrorIconWrapper>
                                <LineIcon name="cross-circle" />
                            </ErrorIconWrapper>
                            <ErrorMessage style={{"textAlign": "center"}}>{t("register.verify.errors.invalidToken")}</ErrorMessage>
                        </>
                )
            } />
        </>
    )
}

export default RegisterVerify
