import React, { useEffect, useState } from 'react'
import { getCookie } from "../../Utils"
import apiRequest from "../../axios"
import { useTranslation } from "react-i18next"
import { ErrorMessage } from "../custom/ErrorMessage"
import { Button, Form } from "react-bootstrap"
import { RightSide, ShowEditToggleElement } from '../Forms/FormElements'
import { LoadingIcon } from '../custom/LoadingIcon'

const editableValues = {
    "username": {
        "type": "text",
        "name": "generally.username"
    },
    "email": {
        "type": "email",
        "name": "generally.email"
    },
    "group": {
        "type": "select",
        "options": ["admin", "member"],
        "name": "generally.group"
    },
    "gender": {
        "type": "select",
        "options": ["m", "f", "x"],
        "name": "generally.personalInputs.gender"
    },
    "first_name": {
        "type": "text",
        "name": "generally.personalInputs.first_name"
    },
    "last_name": {
        "type": "text",
        "name": "generally.personalInputs.last_name"
    },
    "address_street": {
        "type": "text",
        "name": "generally.personalInputs.address_street"
    },
    "address_number": {
        "type": "number",
        "name": "generally.personalInputs.address_number"
    },
    "address_zip": {
        "type": "number",
        "name": "generally.personalInputs.address_zip"
    },
    "address_city": {
        "type": "text",
        "name": "generally.personalInputs.address_city"
    },
    "address_region": {
        "type": "text",
        "name": "generally.personalInputs.address_region"
    },
    "address_country": {
        "type": "text",
        "name": "generally.personalInputs.address_country"
    },
    "phone": {
        "type": "tel",
        "name": "generally.personalInputs.phone"
    }
}

const UserDataEdit = ({ admin = false, userId, selectedEditableValues = [
    "gender",
    "first_name",
    "last_name",
    "address_street",
    "address_number",
    "address_zip",
    "address_city",
    "address_region",
    "address_country"
] }) => {

    const { t } = useTranslation()

    const [user, setUser] = useState({})
    const [userEditedValue, setUserEditedValue] = useState({})
    // when edit is true, then only update "userEditedValue". Else update ALSO "user"
    const updateUserValues = (edit, valueObj) => {
        setUserEditedValue({ ...userEditedValue, ...valueObj })
        if (!edit) {
            setUser({ ...userEditedValue, ...valueObj })
        }
    }
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)
    const [editMode, setEditMode] = useState(false)

    // save data and return to normal user view
    const tryToSave = () => {
        setLoading(true)

        // put only editableValues in request body
        const dataObj = {}
        selectedEditableValues.forEach(editableValue => {
            dataObj[editableValue] = userEditedValue[editableValue]
        });

        // PUT-Request to update users data
        apiRequest("put", "/user/" + userId, dataObj, () => { }, (data) => {
            // reset error message
            setError("")

            // set user data to edited data
            setUser(userEditedValue)

            // exit edit mode and go back to user data
            setEditMode(false)

            setLoading(false)
        }, (errMessage) => {
            // on error show error-message
            setError(errMessage)

            setLoading(false)
        }, getCookie("access"))
    }

    // leave edit menu
    const cancelEdit = () => {
        setUserEditedValue({})
        setEditMode(false)
    }

    // load users data
    useEffect(() => {
        apiRequest("get", "/user/" + userId, {}, () => { }, (data) => {
            setError("")
            setUser(data.user)
            setLoading(false)
        }, (errMessage) => {
            setError(t(errMessage))
            setLoading(false)
        }, getCookie("access"))
    }, [])

    return (
        <>
            <LoadingIcon show={loading} />
            <ErrorMessage>{error}</ErrorMessage>
            {
                !loading &&
                <>
                    {
                        selectedEditableValues.map((editableValue) =>
                            <ShowEditToggleElement editMode={editMode} what={t(editableValues[editableValue].name)} value={user[editableValue]} handleChange={
                                (changedElmt) => {
                                    const updateObj = {}
                                    updateObj[editableValue] = changedElmt.target.value
                                    updateUserValues(true, updateObj)
                                }
                            } />)
                    }
                    <ShowEditToggleElement editMode={editMode} disabled what={"Guthaben"} value={user.balance} />
                    <ShowEditToggleElement editMode={editMode} disabled what={"Status"} value={user.status} />
                    <RightSide>
                        {
                            (editMode &&
                                <>
                                    <Button onClick={cancelEdit} variant="danger" style={{ "marginRight": "12px" }}>Abbrechen</Button>
                                    <Button onClick={tryToSave}>Speichern</Button>
                                </>) ||
                            <Button onClick={() => setEditMode(true)}>Nutzerdaten bearbeiten</Button>
                        }
                    </RightSide>
                </>
            }
        </>
    )
}

export default UserDataEdit