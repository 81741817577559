import React from 'react'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'
import ProductReconfigurator from '../components/ProductConfigurator/productReconfigurator'

const ProductReconfigurePage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} content={<ProductReconfigurator />} />
        </>
    )
}

export default ProductReconfigurePage
