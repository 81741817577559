import React from 'react'
import { useHistory, useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import { BackButton } from '../../custom/Backbutton'
import { getCookie } from '../../../Utils'
import apiRequest from '../../../axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { RightSide, ShowEditToggleElementSmall } from '../../Forms/FormElements'
import { ErrorMessage } from '../../custom/ErrorMessage'

const CouponManage = ({create = false}) => {

    const history = useHistory()

    const [coupon, setCoupon] = useState([])
    const [couponEditedValue, setCouponEditedValue] = useState([])
    const [error, setError] = useState("")
    const [editMode, setEditMode] = useState(false)
    const [noValidJsonConfiguration, setNoValidJsonConfiguration] = useState(false)

    const { code } = useParams()

    useEffect(() => {
        if (!create) {
            apiRequest("get", "/coupons/" + code, {}, () => { }, (data) => {
                setError("")
                setCoupon(data.coupon)
            }, (errMessage) => {
                setError(errMessage)
            }, getCookie("access"))
        }
    }, [])

    const updateCouponValues = (edit, valueObj) => {
        setCouponEditedValue({ ...couponEditedValue, ...valueObj })
        if (!edit) {
            setCoupon({ ...couponEditedValue, ...valueObj })
        }
    }

    const cancel = () => {
        if (create) {
            history.push("/adminpanel/coupons")
        }
        setCouponEditedValue([])
        setEditMode(false)
    }

    const tryToSave = () => {
        if (create) {
            const enoughFilledToCreate = Object.keys(editableFields).filter(option => editableFields[option].requiredOnCreate).every(option => {
                if (!couponEditedValue[option]) {
                    return false
                }
                return true
            })
            if (!enoughFilledToCreate) {
                setError("Bitte fülle alle Felder aus")
                return
            }
            apiRequest("post", "/coupons", couponEditedValue, () => { }, (data) => {
                history.push("/adminpanel/coupons/" + data.coupon.code)
            }, (errMessage) => {
                setError(errMessage)
            }, getCookie("access"))
            return
        }
        if (Object.keys(couponEditedValue).length === 0) {
            setError("Es wurden keine Änderungen vorgenommen")
            return
        }
        apiRequest("put", "/coupons/" + code, couponEditedValue, () => { }, (data) => {
            setError("")
            setEditMode(false)
            setCoupon(data.coupon)
        }, (errMessage) => {
            setError(errMessage)
        }, getCookie("access"))
    }

    const editableFields = {
        "code": { name: "Coupon-Code", notUsed: true, used: false },
        "couponType": { name: "Coupon-Type (PERCENTAGE, AMOUNT)", notUsed: true, used: false, requiredOnCreate: true },
        "value": { name: "Coupon-Wert", notUsed: true, used: false, requiredOnCreate: true },
        "uses": { name: "Maximale Benutzungen", notUsed: true, used: true, requiredOnCreate: true },
        "used": { name: "Wie oft benutzt?", notUsed: false, used: false },
        "minOrderValue": { name: "Mindest-Bestellwert. Bei couponType = \"amount\" sollte der Mindestbestellwert mindestens auf den Rabattwert gesetzt werden, ansonsten kann dem Nutzer Geld geschenkt werden - meißt nicht gewollt.", notUsed: true, used: false },
        "expire": { name: "Ablaufdatum", notUsed: true, used: false, requiredOnCreate: true },
        "productType": { name: "Limitiert auf folgenden Produkt-Typ", notUsed: true, used: false },
        "limitedToMinDurationInDays": { name: "Limitiert auf folgende Mindest-Laufzeit", notUsed: true, used: false },
        "onlyForUser": { name: "Nur für bestimmten Benutzer bestimmt (Benutzername)", notUsed: true, used: false },
        "user_creator": { name: "Coupon-Ersteller", notUsed: false, used: false },
        "create_date": { name: "Coupon-Erstell-Datum", notUsed: false, used: false },
        "event": { name: "Event (zur gruppierung von Coupons)", notUsed: true, used: true, requiredOnCreate: false },
        "description": { name: "Beschreibung", notUsed: true, used: true, requiredOnCreate: true },
        "active": { name: "Ist Coupon aktiv?", notUsed: true, used: true }
    }

    return (
        <>
            <BackButton to="/adminpanel/coupons" />
            <h1>Couponverwaltung</h1>
            <ErrorMessage>{error}</ErrorMessage>
            {
                Object.keys(editableFields).filter(option => !create || editableFields[option].notUsed).map(couponOption =>
                    <ShowEditToggleElementSmall small editMode={editMode || create} disabled={!editableFields[couponOption][coupon.used > 0 ? "used" : "notUsed"]} what={editableFields[couponOption].name + (create && editableFields[couponOption].requiredOnCreate ? " (verpflichtend)" : "")} value={coupon[couponOption]} handleChange={
                        (changedElmt) => {
                            const updateObj = {}
                            updateObj[couponOption] = changedElmt.target.value
                            updateCouponValues(true, updateObj)
                        }
                    } />)
            }
            <ShowEditToggleElementSmall small editMode={editMode || create} disabled={coupon.used > 0} what={"min config"} value={JSON.stringify(coupon["limitedToMinConfiguration"])} handleChange={
                (changedElmt) => {
                    const updateObj = {}
                    let parsedJsonObject
                    try {
                        parsedJsonObject = JSON.parse(changedElmt.target.value)
                        setNoValidJsonConfiguration(false)
                    } catch (err) {
                        setNoValidJsonConfiguration(true)
                    }
                    updateObj["limitedToMinConfiguration"] = parsedJsonObject

                    // when updating configuration, the productType has to be sent as well. just ensure that productType is at least set to the last value
                    if (!couponEditedValue.productType) {
                        couponEditedValue.productType = coupon.productType
                    }

                    updateCouponValues(true, updateObj)
                }
            } />
            {
                noValidJsonConfiguration && <ErrorMessage>Die eingegebene JSON-Konfiguration ist nicht gültig</ErrorMessage>
            }
            <br />
            <RightSide>
                {
                    ((create || editMode) &&
                        <>
                            <Button onClick={cancel} variant="danger" style={{ "marginRight": "12px" }}>Abbrechen</Button>
                            <Button onClick={tryToSave}>{create ? "Erstellen" : "Speichern"}</Button>
                        </>) ||
                    <Button onClick={() => setEditMode(true)}>Coupondaten bearbeiten</Button>
                }
            </RightSide>
        </>
    )

}

export default CouponManage