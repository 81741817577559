import React, { useState, useEffect, useContext } from 'react'
import { FormButton, SwitchWithArrow, PriceField, FormInputField, RightSide, InputLabel, SummaryPoint, SummaryHeading, SuccessIconWrapper, FormButtonRightForTextField, FormTextInputLeftForButton } from './FormElements'
import Form from "react-bootstrap/Form"
import { convertPriceToString, getCookie } from "../../Utils"
import { useTranslation } from 'react-i18next'
import apiRequest from "../../axios"
import { LoadingIcon } from '../custom/LoadingIcon'
import { ErrorMessage } from '../custom/ErrorMessage'
import Button from '../custom/Button'
import { Link, useLocation } from 'react-router-dom'
import LineIcon from "react-lineicons"
import QuickForm from '../custom/QuickForm'
import { UserContext } from '../../provider/userProvider'
import queryString from "query-string"
import swal from '@sweetalert/with-react'

const ConfiguratorForm = ({ productType, formConfiguration, isReconfigureFormForIdentifier }) => {
    const isPersonalDataInputNeeded = productType === "domains" // put this in future in the config of the products, maybe more products with personal data (e.g. personal jobs)
    // constant, to only let the product extend
    const onlyExtend = true
    const reconfigurationMode = !!isReconfigureFormForIdentifier

    // query params
    const { search } = useLocation()
    const queryParams = queryString.parse(search)

    // translations
    const { t } = useTranslation()

    // accesToken for authentication required api-requests
    const accessToken = getCookie("access")

    // user context
    const [values] = useContext(UserContext)

    /*
    |------------------------
    | <STATES>
    |------------------------
    */
    // for showing price
    const [price, setPrice] = useState(0) // price frontend calculates
    const [fetchedPrice, setFetchedPrice] = useState(1000000000) // price backend calculates
    const [fetchedCouponPrice, setFetchedCouponPrice] = useState(1000000000) // price backend calculates
    const [couponPriceReducedAmount, setCouponPriceReducedAmount] = useState("") // price backend calculates

    // for product extend (and reconfigure)
    const [originalPricePerPeriod, setOriginalPricePerPeriod] = useState(0)
    const [fetchedProduct, setFetchedProduct] = useState({})
    const [productIsFetching, setProductIsFetching] = useState(true)

    // for product reconfigure
    const [interimPrice, setInterimPrice] = useState(0)
    const [interimPricePerPeriod, setInterimPricePerPeriod] = useState(0)
    const [interimDuration, setInterimDuration] = useState(0)

    // general
    const [isLoading, setLoading] = useState(false)
    const [isCouponCheckLoading, setIsCouponCheckLoading] = useState(false)
    const [error, setError] = useState("")
    const [couponError, setCouponError] = useState("")

    // state which manages which component is shown (0: configuration/1: verification/2: done)
    const [step, setStep] = useState(0)

    // inputs
    const [duration, setDuration] = useState(formConfiguration.duration.defaultoption)
    let configurationInitialValues = {} // init values for configuration (so if nothing gets selected there is also a value)
    Object.keys(formConfiguration.inputs).forEach((optionName) => {
        const { defaultoption, type } = formConfiguration.inputs[optionName]
        if (type === "select") {
            configurationInitialValues[optionName] = queryParams[optionName] ? queryParams[optionName] : defaultoption
        } else if (type === "text") {
            configurationInitialValues[optionName] = queryParams[optionName] ? queryParams[optionName] : ""
        }
    })
    const [configuration, setConfiguration] = useState(configurationInitialValues)
    const [couponCode, setCouponCode] = useState("")
    const [isCouponCodeValid, setIsCouponCodeValid] = useState(false)
    const [agbAndWithdrawalAccepted, setAgbAndWithdrawalAccepted] = useState(false)
    const [personalDataSource, setPersonalDataSource] = useState("custom") // "profile" or "custom"
    const [personalData, setPersonalData] = useState({ gender: "m" })
    const [fetchedPersonalData, setFetchedPersonalData] = useState({})
    const [personalDataLoading, setPersonalDataLoading] = useState(true)
    const [personalDataOK, setPersonalDataOK] = useState(true)
    const fetchPersonalData = () => {
        apiRequest("get", "/user/" + values.userId, {}, () => { }, data => {
            // success
            console.log(data)
            isPersonalDataOk(data.user)
                .then(() => {
                    setPersonalDataLoading(false)
                    setFetchedPersonalData(data.user)
                    setPersonalDataOK(true)
                })
                .catch(() => {
                    setPersonalDataLoading(false)
                    setPersonalDataOK(false)
                })
        }, errorMessage => {
            // error
            setPersonalDataLoading(false)
        }, getCookie("access"))
    }

    /*
    |------------------------
    | </STATES>
    |------------------------
    */


    /*
    |------------------------
    | <FUNCTIONS>
    |------------------------
    */
    function calculateExtendPrice() {
        let originalPriceTotal = 0
        Object.keys(fetchedProduct.configuration).forEach(
            (originalOption) => {
                const option = formConfiguration.inputs[originalOption]
                if (option.type !== "select")
                    return

                const originalValue = fetchedProduct.configuration[originalOption]
                return originalPriceTotal += parseFloat(option["options"][originalValue])
            }
        )
        setOriginalPricePerPeriod(originalPriceTotal)

        const expireDate = new Date(fetchedProduct.expire)
        // TODO CHECK IF IT IS NULL!!!!!!!!!
        const dayInMillis = 1000 * 24 * 60 * 60
        const dateDiffInDays = Math.ceil((expireDate - new Date()) / dayInMillis)
        setInterimDuration(dateDiffInDays)

        // get discount
        const discount = formConfiguration["duration"]["options"][duration]

        // add duration to calculcation
        const priceWithDuration = Math.abs(originalPriceTotal * (duration / formConfiguration.duration.daysForInterval))

        // add discount to calculcation
        const priceWithDiscount = priceWithDuration * discount

        // round price to two decimals
        const roundedPrice = Math.round(priceWithDiscount * 100) / 100
        setPrice(roundedPrice)
    }

    function calculatePrice() {

        // calculate prices for selects
        let prices = []
        Object.keys(formConfiguration.inputs).forEach((optionName) => {
            const { type } = formConfiguration.inputs[optionName]
            if (type !== "select")
                return

            const elmt = document.getElementById(optionName)
            prices.push(parseFloat(elmt.options[elmt.selectedIndex].getAttribute("data-value")))
        })

        // add all prices together
        const calculatedPrice = prices.reduce((total, price) => total += price)

        // get duration and discount
        const durationElmt = document.getElementById("duration")
        const selectedDurationOption = durationElmt.options[durationElmt.selectedIndex]
        const duration = selectedDurationOption.getAttribute("value")
        const discount = selectedDurationOption.getAttribute("data-value")

        // add duration to calculcation
        const priceWithDuration = Math.abs(calculatedPrice * (duration / formConfiguration.duration.daysForInterval))

        // add discount to calculcation
        const priceWithDiscount = priceWithDuration * discount

        // round price to two decimals
        const roundedPrice = Math.round(priceWithDiscount * 100) / 100
        setPrice(roundedPrice)
    }
    /*
    |------------------------
    | </FUNCTIONS>
    |------------------------
    */

    useEffect(() => {
        if (reconfigurationMode && Object.keys(fetchedProduct).length > 0) {
            calculateExtendPrice()
        }
    }, [duration])

    useEffect(() => {
        if (!reconfigurationMode) {
            calculatePrice()
        }

        // when form is reconfiguration-form, then fetch product
        if (reconfigurationMode) {
            apiRequest("get", "/products/" + isReconfigureFormForIdentifier, {}, () => {
                setProductIsFetching(true)
                setError(null)
            }, (data) => {
                setError("")
                setFetchedProduct(data.product)
                setProductIsFetching(false)
            }, (errMessage) => {
                setProductIsFetching(false)
                return setError(t(errMessage))
            }, accessToken)
        }
    }, [])

    useEffect(() => {
        if (fetchedProduct && Object.keys(fetchedProduct).length > 0) {
            setTimeout(calculateExtendPrice, 0)
        }
    }, [fetchedProduct])

    if (isReconfigureFormForIdentifier && productIsFetching) {
        return (
            <LoadingIcon show />
        )
    }

    // check if ordering is possible and show product summary
    const next = () => {
        if (reconfigurationMode && Object.keys(fetchedProduct).length === 0)
            return
        // check if order is valid and possible
        const body = reconfigurationMode ?
            {
                durationExtension: duration
            } : {
                duration: duration,
                configuration
            }

        // add coupon code to checkout (if valid) - works for new orders and reconfigurations (order updates)
        if (isCouponCodeValid) {
            body.couponCode = couponCode
        }

        apiRequest("post", reconfigurationMode ? "/orders/update/check/" + fetchedProduct.productId : "/orders/check/" + productType, body, () => {
            setLoading(true)
            setError(null)
        }, (data) => {
            setLoading(false)
            setError("")

            // update fetched data for product summary
            setFetchedPrice(data.originalPrice)
            setFetchedCouponPrice(data.price)

            // show product summary or personal data input
            if (!reconfigurationMode && isPersonalDataInputNeeded) {
                fetchPersonalData()
                return setStep(1)
            }
            setStep(2)

        }, (errMessage) => {
            setLoading(false)
            return setError(t(errMessage))
        }, accessToken, true, "/products/" + productType)
    }

    const isPersonalDataOk = (data) => {
        return new Promise((res, rej) => {
            apiRequest("post", "/orders/checkpersonaldata", data, () => { }, res, rej, accessToken, true, "/products/" + productType)
        })
    }

    const checkPersonalData = () => {
        setLoading(true)
        setError(null)
        isPersonalDataOk(personalDataSource === "profile" ? fetchedPersonalData : personalData)
            .then(data => {
                setLoading(false)
                setError("")

                // show product summary or personal data input
                setStep(2)
            })
            .catch(errMessage => {
                setLoading(false)
                console.log(errMessage)
                console.log(t(errMessage))
                return setError(t(errMessage))
            })
    }

    const checkCouponCode = () => {

        if (!couponCode)
            return setCouponError(t("order.couponCodeEmpty"))

        setIsCouponCheckLoading(true)
        setCouponError(null)
        const body =
            {
                productType,
                configuration,
                duration: duration
            }

        apiRequest("post", "/coupons/" + couponCode + "/validate?priceCalc=true", body, () => { }, (data) => {
            setIsCouponCheckLoading(false)
            if (data.valid) {
                setCouponError("")
                setIsCouponCodeValid(true)
                setFetchedPrice(data.originalPrice)
                setCouponPriceReducedAmount(data.reducedBy)
                setFetchedCouponPrice(data.price)
                swal(t("order.couponCodeValid"), "", "success")
                return
            }
            setCouponError(t("order.couponCodeInvalid"))
            setIsCouponCodeValid(false)
        }, (errMessage) => {
            setIsCouponCheckLoading(false)
            return setCouponError(t(errMessage))
        }, accessToken, true, "/products/" + productType)
    }

    // order product
    const orderProduct = () => {

        if (!agbAndWithdrawalAccepted) {
            return setError(t("order.must_accept_agb_and_withdrawal"))
        }

        if (reconfigurationMode && Object.keys(fetchedProduct).length === 0)
            return

        const personalDataToSend = isPersonalDataInputNeeded ? personalDataSource === "profile" ? { "personalData": fetchedPersonalData } : { "personalData": personalData } : {}

        // check if order is valid and possible
        const body = reconfigurationMode ?
            {
                durationExtension: duration
            } : {
                duration: duration,
                configuration,
                ...personalDataToSend
            }

        // add coupon code to checkout (if valid) - works for new orders and reconfigurations (order updates)
        if (isCouponCodeValid) {
            body.couponCode = couponCode
        }

        apiRequest("post", reconfigurationMode ? "/orders/update/" + fetchedProduct.productId : "/orders/" + productType, body, () => {
            setLoading(true)
            setError(null)
        }, (data) => {
            setLoading(false)
            setError("")

            // process fetched api data
            // TODO

            // show order finish
            setStep(3)
        }, (errMessage) => {
            setLoading(false)
            return setError(t(errMessage))
        }, accessToken)
    }

    const shiftDurationDiscounts = (durationAndDiscounts, daysOverhead) => {

        /**
         * get discount from any duration
         * @param {number} anyDuration 
         * @returns 
         */
        function getDiscount(anyDuration) {
            let first = true
            let lastDurationOvercame
            for (let duration in durationAndDiscounts) {
                duration = parseFloat(duration)
                if (first) {
                    // if anyDuration smaller than smallest duration, then use discount of smallest duration
                    if (anyDuration < duration) {
                        return durationAndDiscounts[duration]
                    }
                    first = false
                }
                if (anyDuration >= duration)
                    lastDurationOvercame = duration
                else
                    return durationAndDiscounts[lastDurationOvercame]
            }
        }

        const newDurationDiscounts = {}
        for (const duration in durationAndDiscounts) {
            const gettedDiscount = getDiscount(parseFloat(duration))
            newDurationDiscounts[duration] = gettedDiscount
        }
        return newDurationDiscounts
    }

    const configurationElements = <>
        <ErrorMessage>{error}</ErrorMessage>
        {
            isReconfigureFormForIdentifier && !onlyExtend &&
            <p>{t("pages.products.original_result")}: {convertPriceToString(originalPricePerPeriod)} €/{formConfiguration.duration.daysForInterval + " " + t("pages.products.duration.unit")} ({t("pages.products.without_discounts")})</p>
        }
        {
            // annotation for period duration
            !reconfigurationMode &&
            <p style={{ "marginBottom": "-15px" }}>({t("pages.products.pricesForEach")} {formConfiguration.duration.daysForInterval} {t("pages.products.duration.unit")})</p>
        }
        {
            !(isReconfigureFormForIdentifier && onlyExtend) && Object.keys(formConfiguration.inputs).map((optionName) => {
                const { options, type } = formConfiguration.inputs[optionName]

                return (
                    <ConfigurationInput
                        key={optionName}
                        id={optionName}
                        name={t("products." + productType + ".inputs." + optionName + ".name")}
                        iconname="bolt"
                        type={type}
                        options={options}
                        options_unit={t("products." + productType + ".inputs." + optionName + ".unit")}
                        value={(isReconfigureFormForIdentifier && !onlyExtend) ? fetchedProduct["configuration"][optionName] : configuration[optionName]}
                        onChangeMethod={(elmt) => {
                            calculatePrice()
                            configuration[optionName] = elmt.target.value
                            setConfiguration(configuration)
                        }}
                        isReconfiguration={reconfigurationMode}
                    />
                )
            })
        }

        {
            isReconfigureFormForIdentifier && !onlyExtend &&
            <>
                <p>{t("pages.products.interim_duration")}: {interimDuration + " " + t("pages.products.duration.unit")}</p>
                <p>{t("pages.products.interim_result")}: {convertPriceToString(interimPrice)} € ({t("pages.products.new_result") + ": " + convertPriceToString(interimPricePerPeriod)} €/{formConfiguration.duration.daysForInterval + " " + t("pages.products.duration.unit")})</p>
            </>
        }

        {
            reconfigurationMode && onlyExtend &&
            <>
                <SummaryHeading>{t("pages.products.interim_duration")}: {interimDuration + " " + t("pages.products.duration.unit")}</SummaryHeading>
                <SummaryHeading>{t("pages.products.original_result")}: {convertPriceToString(originalPricePerPeriod)} €/{formConfiguration.duration.daysForInterval + " " + t("pages.products.duration.unit")}</SummaryHeading>
            </>
        }

        <ConfigurationInput
            key="duration"
            duration
            id="duration"
            name={t((isReconfigureFormForIdentifier && !onlyExtend) ? "pages.products.duration.name_extend" : "pages.products.duration.name")}
            iconname="bolt"
            type="select"
            options={(isReconfigureFormForIdentifier && !onlyExtend) ? { 0: 1, ...formConfiguration.duration.options } : formConfiguration.duration.options}
            options_unit={t("pages.products.duration.unit")}
            value={formConfiguration.duration.defaultoption}
            onChangeMethod={(elmt) => {
                setDuration(elmt.target.value)
                if (reconfigurationMode)
                    calculateExtendPrice()
                else
                    calculatePrice()
            }}
            isReconfiguration={reconfigurationMode}
        />
        <PriceField>{t("generally.price")}: {convertPriceToString(price)} €</PriceField>
        <RightSide>
            <LoadingIcon show={isLoading} />
            <FormButton onClick={next} disabled={isLoading}>{t("generally.next")}</FormButton>
        </RightSide>
    </>

    const formInputs = {
        "gender": {
            "type": "select",
            "options": {
                "m": t("generally.gender_m"),
                "f": t("generally.gender_f"),
                "x": t("generally.gender_x")
            },
            "name": "generally.personalInputs.gender"
        },
        "first_name": {
            "type": "text",
            "name": "generally.personalInputs.first_name"
        },
        "last_name": {
            "type": "text",
            "name": "generally.personalInputs.last_name"
        },
        "address_street": {
            "type": "text",
            "name": "generally.personalInputs.address_street"
        },
        "address_number": {
            "type": "string",
            "name": "generally.personalInputs.address_number"
        },
        "address_zip": {
            "type": "number",
            "name": "generally.personalInputs.address_zip"
        },
        "address_city": {
            "type": "text",
            "name": "generally.personalInputs.address_city"
        },
        "address_region": {
            "type": "text",
            "name": "generally.personalInputs.address_region"
        },
        "address_country": {
            "type": "text",
            "name": "generally.personalInputs.address_country"
        },
        "email": {
            "type": "email",
            "name": "generally.email"
        },
        "phone": {
            "type": "tel",
            "name": "generally.phone"
        }
    }

    const alternativePersonalDataInput = <>
        <p>{t("order.personalData.needPersonalData")}:</p>
        <Form.Check disabled={!personalDataOK} type="radio" name="privateDataSourceSelect" onClick={() => setPersonalDataSource("profile")} checked={personalDataSource === "profile"} label={
            <>
                <p>{t("order.personalData.sourceProfile")}</p>
                <span style={{ "marginTop": "-17px", "display": "block" }}>
                    <LoadingIcon show={personalDataLoading} />
                    {
                        !personalDataOK && <ErrorMessage>({t("order.personalData.sourceProfileIncomplete")})</ErrorMessage>
                    }
                </span>
            </>} />
        <Form.Check type="radio" name="privateDataSourceSelect" onClick={() => setPersonalDataSource("custom")} checked={personalDataSource === "custom"} label={t("order.personalData.sourceInput")} />
        <hr />
        <ErrorMessage>{error}</ErrorMessage>
        {
            personalDataSource === "custom" ?
                <QuickForm params={formInputs} defaultValues={personalData} handleChange={inputName => e => setPersonalData(prevPersonalData => {
                    prevPersonalData[inputName] = e.target.value
                    return { ...prevPersonalData }
                })} />
                :
                Object.keys(formInputs).map(key => <SummaryPoint>{t(formInputs[key].name)}: {fetchedPersonalData[key]}</SummaryPoint>)
        }
        <RightSide>
            <LoadingIcon show={isLoading} />
            <FormButton onClick={checkPersonalData} disabled={isLoading}>{t("order.personalData.check")}</FormButton>
        </RightSide>
    </>

    const verificationElements = <>
        <ErrorMessage>{error}</ErrorMessage>
        <p>{t("order.check_your_order")}:</p>
        {
            !reconfigurationMode && isPersonalDataInputNeeded && <>
                <SummaryHeading>{t("order.personalData.summary")}:</SummaryHeading>
                {
                    personalDataSource === "profile" ?
                        Object.keys(formInputs).map(key => <SummaryPoint>{t(formInputs[key].name)}: {fetchedPersonalData[key]}</SummaryPoint>)
                        :
                        Object.keys(formInputs).map(key => <SummaryPoint>{t(formInputs[key].name)}: {personalData[key]}</SummaryPoint>)
                }
                <Button style={{ "margin": "7px" }} onClick={() => { setStep(1); setError(""); setLoading(false) }}>{t("order.changeData")}</Button>
                <br /><br />
            </>
        }
        <SummaryHeading>1x {t("products." + productType + ".title") + " " + duration + " " + t("pages.products.duration.unit") + (reconfigurationMode ? " " + t("pages.products.extension_for") : "")}</SummaryHeading>
        {
            console.log(fetchedProduct)
        }
        {
            Object.keys(configuration).map((option) => formConfiguration.inputs[option]["showonsummary"] && <SummaryPoint key={option}>
                {t("products." + productType + ".inputs." + option + ".name") +
                    ": " +
                    (reconfigurationMode ? fetchedProduct.configuration[option] : configuration[option]) + " " +
                    t("products." + productType + ".inputs." + option + ".unit")
                }
            </SummaryPoint>)
        }

        <Button style={{ "margin": "7px" }} onClick={() => { setStep(0); setError(""); setLoading(false) }}>{t("order.changeData")}</Button>

        <InputLabel>
            <ErrorMessage>{couponError}</ErrorMessage>
            <FormTextInputLeftForButton value={couponCode} disabled={isCouponCheckLoading || isCouponCodeValid} style={{ "maxWidth": "225px" }} type="text" placeholder={t("order.couponCode") + " (" + t("generally.optional") + ")"} onChange={(couponTextInput) => setCouponCode(couponTextInput.target.value)} />
            <FormButtonRightForTextField onClick={checkCouponCode} disabled={isCouponCheckLoading || isCouponCodeValid}>{isCouponCodeValid ? t("order.couponCodeValid") : t("order.useCouponCode")}</FormButtonRightForTextField>
        </InputLabel>

        <InputLabel>
            <Form.Check checked={agbAndWithdrawalAccepted} onChange={(aa) => setAgbAndWithdrawalAccepted(aa.target.checked)} type="checkbox" label={t("legal.termsOfServiceAndTermsOfWithdrawalAccept")} />
        </InputLabel>

        {
            isCouponCodeValid ?
                <PriceField>{t("generally.price")}: <s>{convertPriceToString(fetchedPrice)}€</s> <span style={{ "color": "red" }}>{convertPriceToString(fetchedCouponPrice)}€ (-{couponPriceReducedAmount})</span></PriceField>
                :
                <PriceField>{t("generally.price")}: {convertPriceToString(fetchedPrice)} €</PriceField>
        }
        <RightSide>
            <LoadingIcon show={isLoading} />
            <FormButton onClick={orderProduct} disabled={isLoading}>{t("order.order_execute")}</FormButton>
        </RightSide>
    </>

    const doneElements = <>
        <p>{t("order.finished")}</p>
        <SuccessIconWrapper>
            <LineIcon name="checkmark-circle" />
        </SuccessIconWrapper>
        <RightSide>
            <Link to="/profile/products">
                <Button>{t("pages.products.your_products")}</Button>
            </Link>
        </RightSide>
    </>

    return (
        <>
            {
                (step === 0 && configurationElements) ||
                (step === 1 && alternativePersonalDataInput) ||
                (step === 2 && verificationElements) ||
                (step === 3 && doneElements)
            }

        </>
    )
}

// input field - it can be a select or a text field for custom input.
export const ConfigurationInput = ({ id, duration, name, iconname, type, options, options_unit, onChangeMethod, label, password, value: selectedValue, isReconfiguration }) => {
    const { t } = useTranslation()

    return (
        <>
            <FormInputField iconName={iconname} name={label ? label : name} inputComponent={
                (() => {
                    if (type === "select") {
                        return <SwitchWithArrow defaultValue={selectedValue} id={id} as="select" onChange={onChangeMethod} placeholder={name} style={{ "cursor": "pointer" }}>
                            {
                                (
                                    () => {
                                        let createdElements = []
                                        let i = 0
                                        for (const key in options) {
                                            i++
                                            let value = isReconfiguration && !duration ? options[key] - options[selectedValue] : options[key]
                                            let renderedValue = value
                                            let value_unit = ""
                                            if (duration) {
                                                // set unit to percent
                                                value_unit = "%"

                                                // render duration
                                                renderedValue = Math.round(value * 100 - 100)
                                                if (renderedValue > 0)
                                                    renderedValue = "+" + renderedValue
                                            } else {
                                                // set unit to euro
                                                value_unit = "€"

                                                // render price
                                                renderedValue = (value >= 0 ? "+" : "") + convertPriceToString(value)
                                            }

                                            const unitInfo = duration && value === 1 ? "" : " (" + renderedValue + " " + value_unit + ")"
                                            const innerHtml = (duration && key === "0") ? t("pages.products.no_extension") : key + " " + options_unit + unitInfo
                                            createdElements.push(<option value={key} key={value + key} data-value={options[key]} data-value-reconfigure={value}>{innerHtml}</option>)
                                        }
                                        return createdElements
                                    }
                                )()
                            }
                        </SwitchWithArrow>
                    }
                    return <Form.Control onChange={onChangeMethod} id={id} type={password ? "password" : "text"} placeholder={name} defaultValue={selectedValue} />

                })()
            } />
        </>
    )
}


export default ConfiguratorForm
