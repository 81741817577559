import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import ClaimGameCloudAccount from '../components/Profile/claimgamecloudaccount'

const ClaimGameCloudAccountPage = () => {
    return (
        <>
            <Navigation />
            <ClaimGameCloudAccount />
        </>
    )
}

export default ClaimGameCloudAccountPage
