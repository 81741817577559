import { useState } from "react"
import { Collapsable, CollapsableContent, DropdownIconWrapper, FAQNode, Text, Title } from "./FAQElements"
import LineIcon from "react-lineicons"
import { useTranslation } from "react-i18next"

const faqTranslationKeys = [
    "serverNotStarting"
]

const FAQ = () => {

    const { t } = useTranslation()

    return (
        <>
            {
                faqTranslationKeys.map(key => <FAQPoint question={t("pages.faq.questions." + key + ".question")} answer={t("pages.faq.questions." + key + ".answer")} />)
            }
            <Text>{t("pages.faq.faqInfo")}</Text>
        </>
    )
}

const FAQPoint = ({ question, answer }) => {

    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (open) {
            return setOpen(false)
        }
        return setOpen(true)
    }

    return (
        <FAQNode>
            <Title onClick={toggleOpen}>
                <Text>{question}</Text>
                <DropdownIconWrapper>
                    <LineIcon name={open ? "minus" : "plus"} />
                </DropdownIconWrapper>
            </Title>
            <Collapsable show={open}>
                <CollapsableContent>
                    {answer}
                </CollapsableContent>
            </Collapsable>
        </FAQNode>
    )
}

export default FAQ
