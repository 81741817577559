import styled from "styled-components"

export const ProfileInformationWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`

export const Part = styled.div`
    width: 50%;
    min-width: 150px;
    padding: 10px;

    @media (max-width: 576px) {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
    }
`

export const ProfileInfoHeading = styled.p`
    text-align: center;
    font-size: 30px;
`