import React, { useEffect, useState } from 'react'
import ConfiguratorForm from '../Forms/configurator'
import {
    useParams,
    Redirect
} from "react-router-dom";
import { useTranslation } from "react-i18next"
import products from "../../data/products"
import { BackButton } from '../custom/Backbutton';
import { getCookie } from '../../Utils';
import apiRequest from '../../axios';
import { LoadingIcon } from '../custom/LoadingIcon';

const ProductReconfigurator = () => {

    let { productId } = useParams()
    const { t } = useTranslation()


    // accesToken for authentication required api-requests
    const accessToken = getCookie("access")

    // einfacher das schnell hier nochmal zu fetchen
    const [fetchedProduct, setFetchedProduct] = useState({})
    const [productIsFetching, setProductIsFetching] = useState(true)
    const fetchProductData = () => {
        apiRequest("get", "/products/" + productId, {}, () => {
            setProductIsFetching(true)
        }, (data) => {
            setFetchedProduct(data.product)
            setProductIsFetching(false)
        }, (errMessage) => {
            setProductIsFetching(false)
        }, accessToken)
    }

    useEffect(() => {
        fetchProductData()
    }, [])

    return (
        <>
            <BackButton to={"/profile/products/" + productId} />
            <LoadingIcon show={productIsFetching} />
            {
                !productIsFetching &&
                <>
                    <h2>{t("products." + fetchedProduct.productType + ".title")} - {t("pages.products.product_extend")}</h2>
                    <ConfiguratorForm productType={fetchedProduct.productType} formConfiguration={products[fetchedProduct.productType].configuration} isReconfigureFormForIdentifier={productId} />
                </>
            }
        </>
    )
}

export default ProductReconfigurator
