import React from 'react'
import LineIcon from "react-lineicons"
import styled, { keyframes } from 'styled-components'

export const LoadingIcon = ({ show }) => {
    return (
        <>
            <LoadingIconWrapper show={show}>
                <LineIcon name="spinner-solid" />
            </LoadingIconWrapper>
        </>
    )
}

const loadingRotation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

const LoadingIconWrapper = styled.i`
    animation: ${loadingRotation} 0.5s linear infinite;
    display: ${({show}) => show ? "inline-block" : "none"};
    margin-right: 7px;
`