import React, { useEffect, useState } from 'react'
import { Button, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { getCookie } from "../../../Utils"
import apiRequest from "../../../axios"
import { useTranslation } from "react-i18next"
import { ErrorMessage } from "../../custom/ErrorMessage"
import { BackButton } from "../../custom/Backbutton"
import { Text } from '../../Forms/FormElements'

const UserList = () => {

    const { t } = useTranslation()

    const [users, setUsers] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {
        apiRequest("get", "/user", {}, () => { }, (data) => {
            setError("")
            setUsers(data.users)
        }, (errMessage) => {
            setError(t(errMessage))
        }, getCookie("access"))
    }, [])

    return (
        <>
            <BackButton to="/adminpanel" />
            <h1>Benutzerverwaltung</h1>
            <Text>Alle Nutzer:</Text>
            <ErrorMessage>{error}</ErrorMessage>
            {
                (users && <Table>
                    <thead>
                        <tr>
                            <th>Nutzername</th>
                            <th>E-Mail</th>
                            <th>Guthaben</th>
                            <th>Verwalden</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(entry =>
                            <tr>
                                <td>{entry.username}</td>
                                <td>{entry.email}</td>
                                <td>{entry.balance}</td>
                                <td><Link to={"/adminpanel/user/" + entry._id}><Button>Verwalden</Button></Link></td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                ) ||

                // if no data
                <p>Keine Daten</p>
            }
        </>
    )
}

export default UserList