import React, { useState, useContext } from 'react'
import Form from "react-bootstrap/Form"
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormButton, FormInputField, RightSide } from './FormElements'
import apiRequest from "../../axios"
import { UserContext } from '../../provider/userProvider'
import { useParams } from 'react-router'
import { getCookie } from "../../Utils"

const PasswordResetForm = ({ isNormalReset }) => {
    const { t } = useTranslation()

    const [value] = useContext(UserContext)

    // user data
    const methods = useContext(UserContext)[1]

    const [currentPassword, setCurrentPassword] = useState()
    const [password, setPassword] = useState()
    const [passwordRepeat, setPasswordRepeat] = useState()

    const [showLoading, setShowLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const [error, setErrorMsg] = useState("")

    const { token } = useParams()

    async function resetPassword() {

        if (!password) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        if (isNormalReset && !currentPassword) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        if (password !== passwordRepeat) {
            return setErrorMsg(t("register.errors.passwordsDontMatch"))
        }

        const apiRoute = isNormalReset ? "/user/" + value.userId + "/password" : "/auth/resetPassword"
        const body = isNormalReset ? {
            oldPassword: currentPassword,
            password
        } : {
            token,
            newpassword: password
        }
        // do api request
        apiRequest(isNormalReset ? "put" : "post", apiRoute, body, () => {
            setShowLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setShowLoading(false)
            setSuccess(true)
        }, (errMessage) => {
            setShowLoading(false)
            return setErrorMsg(t(errMessage))
        }, getCookie("access"))

    }

    if (success)
        return (
            <h1>{t(isNormalReset ? "pages.profile.changePasswordSuccess" : "pages.passwordReset.successful")}</h1>
        )

    return (
        <>
            <ErrorMessage>{error}</ErrorMessage>
            {
                isNormalReset &&
                <FormInputField iconName="key" name={t("generally.currentPassword")} inputComponent={
                    <Form.Control type="password" onChange={e => setCurrentPassword(e.target.value)} placeholder={t("generally.currentPassword")} />
                } />
            }
            <FormInputField iconName="key" name={t("generally.password")} inputComponent={
                <Form.Control type="password" onChange={e => setPassword(e.target.value)} placeholder={t("generally.password")} />
            } />
            <FormInputField iconName="key" name={t("register.password_repeat")} inputComponent={
                <Form.Control type="password" onChange={e => setPasswordRepeat(e.target.value)} placeholder={t("register.password_repeat")} />
            } />

            <RightSide>
                <FormButton onClick={resetPassword} disabled={showLoading}><LoadingIcon show={showLoading} />{t(isNormalReset ? "pages.profile.changePassword" : "pages.passwordReset.passwordReset")}</FormButton>
            </RightSide>
        </>
    )
}

export default PasswordResetForm
