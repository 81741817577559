import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import InfoSite from '../../components/InfoSite'
import Navigation from '../../components/Navigation/Navigation'

const AdminPanel = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide="true" content={
                <>
                    <h1>Adminpanel</h1>
                    <hr />
                    <h2>Benutzerverwaltung:</h2>
                    <Link to="/adminpanel/user/">
                        <Button>Alle Benutzer einsehen</Button>
                    </Link>
                    <hr />
                    <h2>Produktverwaltung:</h2>
                    <Link to="/adminpanel/products/">
                        <Button>Produkte</Button>
                    </Link>
                    <hr />
                    <h2>Bestellverwaltung:</h2>
                    <Link to="/adminpanel/orders/">
                        <Button>Bestellübersicht</Button>
                    </Link>
                    <hr />
                    <h2>Transaktionsverwaltung:</h2>
                    <Link to="/adminpanel/transactions/">
                        <Button>Transaktionen</Button>
                    </Link>
                    <hr />
                    <h2>Couponverwaltung:</h2>
                    <Link to="/adminpanel/coupons/">
                        <Button>Coupons</Button>
                    </Link>
                    <hr />
                </>
            } />
        </>
    )
}

export default AdminPanel
