import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import apiRequest from '../../axios';
import { useContext } from 'react';
import { UserContext } from '../../provider/userProvider';
import { LoadingIcon } from '../custom/LoadingIcon';
import { ErrorMessage } from '../custom/ErrorMessage';
import { generateSummaryFromConfiguration, getCookie } from '../../Utils';
import { SummaryHeading, SummaryPoint } from '../Forms/FormElements';
import products from "../../data/products"
import { Button } from 'react-bootstrap';
import { ProductSuspendedText, SectionHeader } from './ProductManageElements';
import swal from '@sweetalert/with-react';

const ProductManage = ({ admin }) => {
    const { t } = useTranslation()
    const [value] = useContext(UserContext)
    let { productType, productId } = useParams()

    let apiRoute = "/products/" + productId
    if (admin) {
        apiRoute = "/products/" + productId
    }

    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [fetchedProduct, setFetchedProduct] = useState()

    const accessToken = getCookie("access")
    const fetchProducts = () => {
        apiRequest("get", apiRoute, null, () => {
            setLoading(true)
        }, (data) => {
            // on success
            setLoading(false)

            setErrorMessage("")

            setFetchedProduct(data.product)
        }, (errorMessage) => {
            setLoading(false)
            // error
            setErrorMessage(t(errorMessage))
        }, accessToken)
    }

    useEffect(() => {
        fetchProducts()
    }, [])


    return (
        <>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            {
                // show loading animation when loading products
                (loading && <LoadingIcon show={loading} />) ||

                (!fetchedProduct && <p>{t("pages.products.product_not_found")}</p>) ||
                (
                    <>
                        <SectionHeader>{t("pages.products.product_information")}:</SectionHeader>
                        {
                            fetchedProduct && fetchedProduct.suspension &&
                            <>
                                <ProductSuspendedText>{t("pages.products.suspended")}</ProductSuspendedText>
                            </>
                        }
                        <SummaryHeading>{t("pages.products.type")}: {t("products." + fetchedProduct.productType + ".title")}</SummaryHeading>
                        <SummaryHeading>{t("pages.products.configuration")}:</SummaryHeading>
                        {
                            generateSummaryFromConfiguration(t, fetchedProduct.productType, fetchedProduct.configuration)
                        }
                        <hr />
                        <SectionHeader>{t("pages.products.product_manage")}:</SectionHeader>
                        {
                            products[fetchedProduct.productType].managepage()
                        }
                        <br /><br />
                        {
                            !admin &&
                            <>
                                <Link to={"/profile/products/" + productId + "/reconfigure"}>
                                    <Button>{t("pages.products.product_extend")}</Button>
                                </Link>
                            </>
                        }
                        <hr />
                        {
                            admin &&
                            <>
                                <SuspensionButton
                                    t={t}
                                    productType={productType}
                                    productId={productId}
                                    accessToken={accessToken}
                                    product={fetchedProduct}
                                    fetchProducts={fetchProducts}
                                />
                                <br /><br />
                                <DeleteButton
                                    t={t}
                                    productType={productType}
                                    productId={productId}
                                    accessToken={accessToken}
                                    product={fetchedProduct}
                                />
                            </>
                        }
                    </>
                )
            }
        </>
    );
}

const SuspensionButton = ({ t, productType, productId, accessToken, product, fetchProducts }) => {

    const [loading, setLoading] = useState(false)
    const [isSuspended, setIsSuspended] = useState(!!product.suspension)
    
    const suspendProduct = () => {
        swal({
            text: 'Give a reason:',
            content: "input",
            button: {
                text: "Restrict!",
                closeModal: false,
            },
        })
            .then(reason => {
                if (!reason)
                    throw null

                return apiRequest(
                    "POST",
                    "/products/" + productId + "/suspension",
                    { reason },
                    () => {
                        setLoading(true)
                    },
                    (data) => {
                        setLoading(false)
                        fetchProducts()
                        swal(t("api." + data.message), "", "success")
                    },
                    (errMessage) => {
                        fetchProducts()
                        swal("Oh no!", t(errMessage), "error")
                    },
                    accessToken)
            })
    }

    const unsuspendProduct = () => {
        return apiRequest(
            "DELETE",
            "/products/" + productId + "/suspension", {},
            () => {
                setLoading(true)
            },
            (data) => {
                setLoading(false)
                setIsSuspended(false)
                swal(t("api." + data.message), "", "success")
            },
            (errMessage) => {
                setLoading(false)
                swal("Oh no!", t(errMessage), "error")
            },
            accessToken)
    }

    return (
        <>
            <Button variant="danger" onClick={isSuspended ? unsuspendProduct : suspendProduct} disabled={loading}>
                <LoadingIcon show={loading} />
                {isSuspended ? "Produkt freigeben" : "Produkt suspendieren"}
            </Button>
            {
                isSuspended ?
                    <>
                        <SummaryPoint>Suspendiert von: <a href={"/adminpanel/user/" + product.suspension.suspender}>{product.suspension.suspender}</a></SummaryPoint>
                        <SummaryPoint>Suspendiert am: {new Date(product.suspension.date).toLocaleString()}</SummaryPoint>
                        <SummaryPoint>Grund: {product.suspension.reason}</SummaryPoint>
                    </>
                    : ""
            }
        </>
    )
}

const DeleteButton = ({ t, productType, productId, accessToken, product }) => {

    const [loading, setLoading] = useState(false)

    const deleteProduct = () => {
        swal({
            text: 'Type in server id to delete: (Product-Id: ' + productId + ')',
            content: "input",
            button: {
                text: "DELETE",
                closeModal: false,
            },
        })
            .then(productIdConfirm => {
                if (!productIdConfirm)
                    throw null

                if (productIdConfirm !== productId)
                    throw null

                return apiRequest(
                    "DELETE",
                    "/products/" + productId,
                    {},
                    () => {
                        setLoading(true)
                    },
                    (data) => {
                        setLoading(false)
                        swal(t("api." + data.message), "", "success")
                    },
                    (errMessage) => {
                        setLoading(false)
                        swal("Oh no!", t(errMessage), "error")
                    },
                    accessToken)
            })
            .catch(() => {
                swal("Oh no!", "type in product id", "error")
            })
    }

    return (
        <Button variant="danger" onClick={deleteProduct}><LoadingIcon show={loading} />Produkt löschen</Button>
    )
}

export default ProductManage
