import React from 'react'
import InfoSite from '../InfoSite'
import ProfileInformation from './profile'

const Profile = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} content={<ProfileInformation />} />
        </>
    )
}

export default Profile
