import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import styled from "styled-components"

export const Subnavigationwrapper = styled.div`
    position: relative;
    padding: 5px;
    background-color: ${props => props.backgroundColor};

    @media (max-width: 350px) {
        height: 72px;
    }
`

export const LeftPart = styled.div`
    display: inline-flex;
    
    @media (max-width: 350px) {
        float: right;
    }
`
export const RightPart = styled.div`
    display: inline-flex;
    top: 0;
    float: right;

    @media (max-width: 350px) {
        margin-top: 10px;
        width: 100vw;
        justify-content: right;
    }
`

export const StyledDropdownToggle = styled(DropdownToggle)`
    font-size: 1rem;
    font-family: "Lato-Medium", sans-serif;
    border: 0;
    background-color: transparent;
    padding: 0;
`

export const CountryFlagImage = styled.img`
    width: 1rem;
    margin-right: 0.3125rem;
`

export const CountryNameText = styled.p`
    display: inline;
`

export const SubnavigationItemWrapper = styled.a`
    text-decoration: none !important;
    color: #000;
`

export const Divider = styled.span`
    &:after {
        content: "—";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-weight: 600;
    }
`