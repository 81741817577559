import React from 'react'
import PasswordResetForm from '../components/Forms/passwordReset'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'

const ResetPasswordPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} title="pages.passwordReset.passwordReset" content={<PasswordResetForm />} />
        </>
    )
}

export default ResetPasswordPage
