import styled from "styled-components"
import React, { useContext } from 'react'
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { UserContext } from "../../provider/userProvider"

const DropdownToggle = styled(Dropdown.Toggle)`
    background-color: ${({ colortype }) => {
        if (!colortype)
            colortype = "primary"
        return "var(--brand_color_" + colortype + ")"
    }} !important;

    &:hover {
        background-color: ${({ colorType }) => {
        if (!colorType)
            colorType = "primary"
        return "var(--brand_color_" + colorType + "_hover)"
    }} !important;
    }
`

const DropdownButton = ({ className, children, dropdownEntries }) => {

    const [values] = useContext(UserContext)

    const dropdownList = []
    dropdownEntries.forEach(({ onClick, href, to, name, whitelistedGroups }) => {

        if (whitelistedGroups && !whitelistedGroups.includes(values.group)) {
            return
        }

        if (to) {
            dropdownList.push(<Link key={to} className={"dropdown-item"} to={to}>{name}</Link>)
            return
        }

        if (href) {
            dropdownList.push(<Dropdown.Item key={href} href={href}>{name}</Dropdown.Item>)
            return
        }

        if (onClick) {
            dropdownList.push(<Dropdown.Item key={name} onClick={onClick}>{name}</Dropdown.Item>)
            return
        }

        return name
        
    })

    return (
        <Dropdown className={className}>
            <DropdownToggle colortype="primary">
                {children}
            </DropdownToggle>

            <Dropdown.Menu>
                {dropdownList}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownButton