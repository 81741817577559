import swal from "@sweetalert/with-react"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import apiRequest from "../../axios"
import { getCookie } from "../../Utils"
import { ErrorMessage } from "../custom/ErrorMessage"
import { LoadingIcon } from "../custom/LoadingIcon"
import { FormButton, FormInputField, RightSide, SwitchWithArrow } from "./FormElements"

const AdminManualTransactionForm = () => {
    const { t } = useTranslation()
    const { transactionId } = useParams()

    const editMode = transactionId !== undefined

    const [userId, setUserId] = useState()
    const [amount, setAmount] = useState()
    const [initAmount, setInitAmount] = useState()
    const [status, setStatus] = useState("PENDING")
    const [initStatus, setInitStatus] = useState()
    const [notes, setNotes] = useState()

    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const history = useHistory()

    async function fetchTransaction() {

        // do api request
        const accessToken = getCookie("access")
        apiRequest("get", "/transactions/" + transactionId, {}, () => {
            setLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setLoading(false)

            if (!data.transaction)
                return setErrorMsg("Transaktion nicht gefunden.")

            setUserId(data.transaction.user)
            setAmount(data.transaction.amount)
            setInitAmount(data.transaction.amount)
            setStatus(data.transaction.transactionStatus)
            setInitStatus(data.transaction.transactionStatus)
            setNotes(data.transaction.notes)

        }, (errMessage) => {
            setLoading(false)
            return setErrorMsg(t(errMessage))
        }, accessToken)

    }

    async function createTransaction() {
        // do api request
        const accessToken = getCookie("access")
        apiRequest("post", "/transactions/", {
            userId: userId,
            amount: amount,
            notes: notes
        }, () => {
            setLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setLoading(false)

            swal("Transaktion erfolgreich erstellt!", "", "success")

            // redirect to edit page
            history.push("/adminpanel/transactions/manually/" + data.transaction._id)

        }, (errMessage) => {
            setLoading(false)
            return setErrorMsg(errMessage)
        }, accessToken)

    }

    async function updateTransaction() {
        // do api request
        const accessToken = getCookie("access")
        const action = initAmount == amount ? "updateStatus" : "updateAmount"
        const body = initAmount == amount ? { newStatus: status } : { newAmount: amount }
        apiRequest("put", "/transactions/" + transactionId + "/" + action, body, () => {
            setLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setLoading(false)

            setUserId(data.updatedTransaction.user)
            setAmount(data.updatedTransaction.amount)
            setInitAmount(data.updatedTransaction.amount)
            setStatus(data.updatedTransaction.transactionStatus)
            setInitStatus(data.updatedTransaction.transactionStatus)
            setNotes(data.updatedTransaction.notes)

            swal("Transaktion erfolgreich getupdated!", "", "success")

        }, (errMessage) => {
            setLoading(false)
            return setErrorMsg(errMessage)
        }, accessToken)

    }

    useEffect(() => {
        if (editMode)
            fetchTransaction()
    }, [])
    
    return <>
        <h1>{
            editMode ?
                `Transaktion ${transactionId} bearbeiten`
                :
                "Transaktion erstellen"
        }</h1>
        <ErrorMessage>{errorMsg}</ErrorMessage>
        {
            loading ?
                <LoadingIcon show />
                : <>
                    <FormInputField iconName="target" name={t("generally.userid")} inputComponent={
                        <Form.Control disabled={editMode} type="text" onChange={e => setUserId(e.target.value)} value={userId} placeholder={t("generally.userid")} />
                    } />
                    {
                        editMode ? <p>(mit einer Aktion entweder Betrag oder Status ändern)</p> : null
                    }
                    <FormInputField iconName="coin" name="Betrag" inputComponent={
                        <Form.Control disabled={(editMode && loading) || (editMode && initStatus !== "PENDING") || (editMode && status != initStatus + "")} type="number" onChange={e => setAmount(e.target.value)} value={amount} placeholder="Betrag" />
                    } />
                    <FormInputField iconName="popup" name="Status" inputComponent={
                        <SwitchWithArrow disabled={!editMode || (editMode && initStatus !== "PENDING") || (editMode && loading) || (editMode && amount != initAmount + "")} as="select" onChange={event => {
                            const status = event.target[event.target.selectedIndex].getAttribute('data-status')
                            setStatus(status)
                        }} placeholder="Status">
                            <option selected={status === "PENDING"} default data-status="PENDING">PENDING</option>
                            <option selected={status === "EXPIRED"} data-status="EXPIRED">EXPIRED</option>
                            <option selected={status === "RETREATED"} data-status="RETREATED">RETREATED</option>
                            <option selected={status === "SUCCESS"} data-status="SUCCESS">SUCCESS</option>
                        </SwitchWithArrow>
                    } />
                    <FormInputField iconName="postcard" name="Notizen" inputComponent={
                        <Form.Control disabled={editMode || loading} type="text" onChange={e => setNotes(e.target.value)} value={notes} placeholder="Notizen" />
                    } />
                    <RightSide>
                        <LoadingIcon show={loading} />
                        <FormButton onClick={editMode ? updateTransaction : createTransaction} disabled={loading}>{editMode ? "Bearbeiten" : "Erstellen"}</FormButton>
                    </RightSide>
                </>
        }

    </>
}

export default AdminManualTransactionForm