import Col from "react-bootstrap/Col"
import styled from "styled-components"
import Form from 'react-bootstrap/Form'

export const ConfiguratorWrapper = styled.div`
    position: relative;
    background-image: url(${({backgroundImg}) => backgroundImg});
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 550px);
    /*padding-top: calc(1.4375rem + 2.25vw);

    @media (min-width: 1200px) {
        padding-top: 3.125rem;
    }*/
`

export const Box = styled(Col)`
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: calc(1.28125rem + 0.375vw);
    max-width: ${({wide}) => wide ? "1400px" : "700px"};
    margin: calc(1.4375rem + 2.25vw) 30px;
    z-index: 2;
    min-height: ${({miniheight}) => miniheight ? "30px" : "400px"};

    @media (min-width: 1200px) {
        padding: 1.5625rem;
        margin-bottom: 3.125rem;
        margin-top: 3.125rem;
    }

    @media (max-width: 350px) {
        margin: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
`

export const ConfigurationInputLabel = styled(Form.Label)`
    display: block;
    margin-top: 30px;
`

export const SwitchWithArrow = styled(Form.Control)`
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`