import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import Register from '../components/Register'

const RegisterPage = () => {
    // TODO check if already logged in

    return (
        <>
            <Navigation />
            <Register />
        </>
    )
}

export default RegisterPage
