import { Redirect, useParams } from "react-router"
import { useTranslation } from "react-i18next"
import InfoSite from "../InfoSite"
import products from "../../data/products"

export const ProductInfo = () => {
    const { t } = useTranslation()
    const { productType } = useParams()

    // redirect to error page, if product doesn't exist
    if (!(productType in products)) {
        return <Redirect to={"/unknownpage"} />
    }
    return <>
        <InfoSite backgroundImg="" content={<>
            <h1>{t("products." + productType + ".title")}</h1>
            {
                products[productType].infopage()
            }
        </>} wide="true" />
    </>
}