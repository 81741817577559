import React from 'react'
import LoginForm from '../Forms/login'
import InfoSite from '../InfoSite'

const Login = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title="login.login" content={<LoginForm />} />
        </>
    )
}

export default Login
