import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import swal from "sweetalert"
import apiRequest from "../../axios"
import { getCookie } from "../../Utils"
import { LoadingIcon } from "../custom/LoadingIcon"
import { FormInputField } from "./FormElements"


const UserRestrictForm = ({ userId }) => {

    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)

    const [allRestrictions, setAllRestrictions] = useState({})
    const [userRestriction, setUserRestriction] = useState({})

    const [selectedRestrictions, setSelectedRestrictions] = useState([])

    const [reason, setReason] = useState("")

    useEffect(() => {
        apiRequest("GET", "/user/" + userId + "/restriction", {}, () => { }, (data) => {
            setLoading(false)
            setAllRestrictions(data.possibleRestrictions)
            setUserRestriction(data.restriction)
        }, (errMessage) => {
            setLoading(false)
        }, getCookie("access"))
    }, [])

    if (loading)
        return <LoadingIcon show />

    const unrestrict = async () => {
        apiRequest("DELETE", "/user/" + userId + "/restriction", {}, () => {
            setLoading(true)
        }, (data) => {
            setUserRestriction({})
            setLoading(false)
        }, (errMessage) => {
            swal(t(errMessage), "", "error")
            setLoading(false)
        }, getCookie("access"))
    }

    if (Object.keys(userRestriction).length > 0)
        return (
            <>
                <p>User ist restricted: (view logs)</p>
                <p>Restricted von: {userRestriction.executor}</p>
                <p>Grund: {userRestriction.reason}</p>
                Restricted permissions:
                <ul>
                    {
                        userRestriction.restrictions.map(perm => <li>{perm}</li>)
                    }
                </ul>
                <Button onClick={unrestrict}>Einschränkung aufheben</Button>
            </>
        )

    const restrict = async () => {
        apiRequest("POST", "/user/" + userId + "/restriction", {
            reason,
            restrictions: selectedRestrictions
        }, () => {
            setLoading(true)
        }, (data) => {
            setUserRestriction(data.restriction)
            setLoading(false)
        }, (errMessage) => {
            swal(t(errMessage), "", "error")
            setLoading(false)
        }, getCookie("access"))
    }

    return (
        <>
            <FormInputField name="Restrictions auswählen:" iconName="bolt" inputComponent={
                <Form.Control multiple as="select" style={{ "height": "300px" }} onChange={e => setSelectedRestrictions([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                    {
                        Object.keys(allRestrictions).map(restrictionGroupKey => {
                            const restrictionGroup = allRestrictions[restrictionGroupKey]
                            return restrictionGroup.permissions.map(permission => <option value={permission}>{permission}</option>)
                        })
                    }
                </Form.Control>
            } />
            <FormInputField name="Kurze und aussagekräftige Begründung" iconName="pencil" inputComponent={
                <Form.Control multiple as="textarea" onChange={e => setReason(e.target.value)}></Form.Control>
            } />
            <Button onClick={restrict}>Restrict</Button>
        </>
    )
}

export default UserRestrictForm