import React, { useState, useEffect } from 'react'
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import apiRequest from '../../axios';
import { useContext } from 'react';
import { UserContext } from '../../provider/userProvider';
import { LoadingIcon } from '../custom/LoadingIcon';
import { ErrorMessage } from '../custom/ErrorMessage';
import { convertPriceToString, generateSummaryFromConfiguration, getCookie } from '../../Utils';
import swal from '@sweetalert/with-react'
import { SummaryHeading, SummaryPoint } from '../Forms/FormElements';

// currently only admin. but to add "normal" fetch, prepare it like this
const OrderList = ({ admin }) => {
    const { t } = useTranslation()
    const [value] = useContext(UserContext)

    let apiRoute = "/user/" + value.userId + "/orders"
    if (admin) {
        apiRoute = "/orders"
    }

    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [fetchedOrders, setFetchedOrders] = useState([])

    const fetchOrders = () => {
        apiRequest("get", apiRoute, null, () => {
            setLoading(true)
        }, (data) => {
            // on success
            setLoading(false)

            setErrorMessage("")

            setFetchedOrders(data.orders)
        }, (errorMessage) => {
            setLoading(false)
            // error
            setErrorMessage(t(errorMessage))
        }, getCookie("access"))
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            {
                // show loading animation when loading orders
                (loading && <LoadingIcon show={loading} />) ||
                (fetchedOrders && fetchedOrders.length > 0) ? 
                <Table>
                    <thead>
                        <tr>
                            <th>{t("pages.orders.order_placed_at")}</th>
                            <th>{t("pages.orders.product_type")}</th>
                            <th>{t("pages.orders.order_type")}</th>
                            <th>{t("pages.orders.order_details")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fetchedOrders.map((order) => {
                                const formattedDate = (new Date(order.createdAt)).toLocaleDateString() + " " + (new Date(order.createdAt)).toLocaleTimeString()
                                const productType = t("products." + order.productType + ".title")
                                const orderType = t("pages.orders.types." + order.orderType)
                                const price = convertPriceToString(order.price) + " €"
                                return <tr key={order._id}>
                                    <td>{formattedDate}</td>
                                    <td>{productType}</td>
                                    <td>{orderType}</td>
                                    <td>
                                        <Button onClick={() => swal(
                                            <div style={{ "textAlign": "left" }}>
                                                <h2>{t("pages.orders.order_details")}:</h2>
                                                <SummaryHeading>{t("pages.orders.order_placed_at")}: {formattedDate}</SummaryHeading>
                                                <SummaryHeading>{t("pages.orders.product_type")}: {productType}</SummaryHeading>
                                                <SummaryHeading>{t("pages.orders.order_type")}: {orderType}</SummaryHeading>
                                                <SummaryHeading>{t("generally.price")}: {price}</SummaryHeading>
                                                <SummaryHeading>{t("pages.orders.configuration")}:</SummaryHeading>
                                                {
                                                    generateSummaryFromConfiguration(t, order.productType, order.configuration)
                                                }
                                            </div>
                                        )}>{t("pages.orders.order_details")}</Button>
                                    </td>
                                </tr>
                            }
                            )
                        }
                    </tbody>
                </Table> : t("pages.orders.noOrders")
            }
        </>
    );
}

export default OrderList
