import React from 'react'
import {
    FooterWrapper,
    Copyright,
    Title,
    Logo,
    StyledLink,
    Description,
    Contact,
    Divider,
    Column,
    Row
} from "./FooterElements"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import LineIcon from "react-lineicons"
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()
    return (
        <>
            <FooterWrapper>
                <Container fluid="">
                    <Row>
                        <Col sm="4">
                            <Logo src={require("../../images/logo_transparent.svg").default} alt="XEONITY" />

                            <Description>{t("footer.description")}</Description>
                        </Col>

                        <Column sm>
                            <Title>{t("footer.legal.legal")}</Title>

                            <StyledLink to="/legal/imprint"><LineIcon name="arrow-right" /> {t("footer.legal.imprint")}</StyledLink>
                            <StyledLink to="/legal/terms-of-privacy"><LineIcon name="arrow-right" /> {t("footer.legal.privacy")}</StyledLink>
                            <StyledLink to="/legal/terms-of-service"><LineIcon name="arrow-right" /> {t("footer.legal.terms-and-conditions")}</StyledLink>
                            <StyledLink to="/legal/terms-of-withdrawal"><LineIcon name="arrow-right" /> {t("footer.legal.cancellation-policy")}</StyledLink>
                        </Column>

                        <Column sm>
                            <Title>{t("products.products")}</Title>

                            <StyledLink to="/products/minecraft-server"><LineIcon name="arrow-right" /> {t("products.minecraft-server.title")}</StyledLink>
                        </Column>

                        {/*
                        removed company part from website. maybe add in future

                        <Column sm>
                            <Title>{t("navigation.main.navigations.company.company")}</Title>

                            <StyledLink href="#"><LineIcon name="arrow-right" /> {t("navigation.main.navigations.company.data-center")}</StyledLink>
                            <StyledLink href="#"><LineIcon name="arrow-right" /> {t("navigation.main.navigations.company.technic")}</StyledLink>
                        </Column>*/}

                        <Column sm>
                            <Title>{t("footer.contact")}</Title>

                            <Contact>
                                Xeonity GbR<br />
                                Bischof-Albert-Weg 12<br />
                                27612, Loxstedt
                            </Contact>
                        </Column>
                    </Row>

                    <Divider />

                    <Copyright>Copyright &copy; {(new Date().getFullYear())} Xeonity GbR</Copyright>
                </Container>
            </FooterWrapper>
        </>
    )
}

export default Footer



