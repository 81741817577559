import React from 'react'
import InfoSite from '../InfoSite'
import ProductsList from './productslist'

const Products = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title="pages.products.your_products" content={<ProductsList />} />
        </>
    )
}

export default Products
