import React from 'react'
import InfoSite from '../components/InfoSite'
import Legal from '../components/legal'
import Navigation from '../components/Navigation/Navigation'

const LegalPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite content={<Legal />} wide="true" />
        </>
    )
}

export default LegalPage
