import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import apiRequest from '../../axios'
import { UserContext } from '../../provider/userProvider'
import { getCookie } from '../../Utils'
import { LoadingIcon } from '../custom/LoadingIcon'
import TicketActionForm from '../Forms/ticketAction'
import { Part } from '../Profile/ProfileElements'
import { ErrorMessage } from "../custom/ErrorMessage"
import { TicketInformationWrapper, TicketInformationPoint, TicketInformationHeading, TicketActionWrapper, TicketActionMessage, TicketActionInfo, TicketTitle } from './TicketElements'

const Ticket = () => {

    const { t } = useTranslation()

    const [value] = useContext(UserContext)

    const { ticketId } = useParams()

    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const [fetchedTicket, setFetchedTicket] = useState({})
    const [ticketActions, setTicketActions] = useState([])

    const open = ticketActions && ticketActions.length > 0 ? ticketActions[0].actionType !== "CLOSE" : true
    let forwarded = false
    let toUser = ""
    for (let ticketActionIndex in ticketActions) {
        const ticketAction = ticketActions[ticketActionIndex].actionType
        if (ticketAction !== "ANSWER" && ticketAction !== "OPEN" && ticketAction !== "CLOSE" && ticketAction !== "FORWARD") {
            forwarded = false
            break
        }
        if (ticketAction === "FORWARD") {
            forwarded = true
            toUser = ticketActions[ticketActionIndex].toUser
            break
        }
    }

    let taken = false
    if (ticketActions && ticketActions.length > 0) {
        for (const ticketActionIndex in ticketActions) {
            const ticketAction = ticketActions[ticketActionIndex]
            if (ticketAction.actionType === "TAKE" || ticketAction.actionType === "FORWARD_ACCEPT") {
                taken = true
                break
            }
        }
    }

    let isSupporter = false
    if (taken) {
        for (const ticketActionIndex in ticketActions) {
            const ticketAction = ticketActions[ticketActionIndex]
            if (ticketAction.actionType === "TAKE" || ticketAction.actionType === "FORWARD_ACCEPT") {
                if (value.userId === ticketAction.user) {
                    isSupporter = true
                }
                break
            }
        }
    }
    const isCreator = (value && fetchedTicket && Object.keys(fetchedTicket).length > 0) ? value.userId === fetchedTicket.creator.toString() : false

    const accessToken = getCookie("access")
    const fetchTicket = async () => {
        apiRequest("GET", "/support/ticketsystem/" + ticketId, {}, () => { }, (data) => {
            setErrorMessage("")
            setFetchedTicket(data.ticket)

            // fetch ticket actions
            apiRequest("GET", "/support/ticketsystem/" + ticketId + "/actions", {}, () => { }, (data) => {
                setErrorMessage("")
                setTicketActions(data.ticketActions)
                setLoading(false)
            }, (errMessage) => {
                setErrorMessage(t(errMessage))
                setLoading(false)
            }, accessToken)

        }, (errMessage) => {
            setErrorMessage(t(errMessage))
            setLoading(false)
        }, accessToken)
    }

    useEffect(() => {
        fetchTicket()
    }, [])

    return (
        <>
            <h1>{t("pages.ticketsystem.ticket") + " #" + ticketId}</h1>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <TicketInformationWrapper>
                {
                    (loading && <div><LoadingIcon show /></div>) ||
                    <>
                        <Part>
                            <TicketInformationHeading>{t("pages.ticketsystem.information")}:</TicketInformationHeading>
                            <TicketInformationPoint>{t("pages.ticketsystem.creator") + ": " + fetchedTicket.username}</TicketInformationPoint>
                            <TicketInformationPoint>{t("generally.createdAt") + ": " + new Date(fetchedTicket.createdAt).toLocaleDateString() + " " + new Date(fetchedTicket.createdAt).toLocaleTimeString()}</TicketInformationPoint>
                            <TicketInformationPoint>{t("pages.ticketsystem.status") + ": " + (open ? t("pages.ticketsystem.status_open") : t("pages.ticketsystem.status_closed"))}</TicketInformationPoint>
                            <TicketInformationPoint>{t("pages.ticketsystem.subject") + ": " + (fetchedTicket.productId ? "#" + fetchedTicket.productId : t("pages.ticketsystem.noProduct"))}</TicketInformationPoint>

                            {
                                <TicketActionForm isForwardedToUser={toUser === value.userId} isSupporter={isSupporter} isCreator={isCreator} forwarded={forwarded} open={open} ticketId={ticketId} taken={taken} updateTicket={fetchTicket} creatorName={fetchedTicket.creator} />
                            }

                        </Part>
                        <Part>
                            <TicketInformationHeading>{t("pages.ticketsystem.history")}:</TicketInformationHeading>
                            {
                                ticketActions.map(ticketAction =>
                                    <>
                                        <TicketActionWrapper>
                                            <TicketActionInfo>{t("pages.ticketsystem.actions_finished." + ticketAction.actionType.toLowerCase()) + " " + t("pages.ticketsystem.from") + " " + ticketAction.username} {ticketAction.toUser !== null ? " an " + ticketAction.toUser : ""}</TicketActionInfo>
                                            <span style={{ "color": "grey", "fontSize": "14px" }}>({new Date(ticketAction.createdAt).toLocaleDateString() + " " + new Date(ticketAction.createdAt).toLocaleTimeString()})</span>
                                            <TicketActionMessage>{ticketAction.text}</TicketActionMessage>
                                        </TicketActionWrapper>
                                        <hr />
                                    </>
                                )
                            }
                            <TicketActionWrapper>
                                <TicketActionInfo>{t("pages.ticketsystem.original_ticket_at") + " " + new Date(fetchedTicket.createdAt).toLocaleDateString() + " " + new Date(fetchedTicket.createdAt).toLocaleTimeString()}</TicketActionInfo>
                                <TicketActionMessage><TicketTitle>{fetchedTicket.title}</TicketTitle><br />{fetchedTicket.text}</TicketActionMessage>
                            </TicketActionWrapper>
                        </Part>
                    </>
                }
            </TicketInformationWrapper>
        </>
    )
}

export default Ticket
