import React from 'react'
import AdminProductList from '../../../components/Admin/Products/productlist'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminProductListPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide="true" content={
                <AdminProductList />
            } />
        </>
    )
}

export default AdminProductListPage
