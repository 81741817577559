import React from 'react'
import ProductConfigurator from '../components/ProductConfigurator'
import Navigation from '../components/Navigation/Navigation'

const ProductOrder = () => {
    return (
        <>
        <Navigation />
            <ProductConfigurator />
        </>
    )
}

export default ProductOrder
