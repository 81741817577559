import swal from '@sweetalert/with-react'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import apiRequest from '../../axios'
import { UserContext } from '../../provider/userProvider'
import { getCookie } from '../../Utils'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormInputField, SwitchWithArrow } from './FormElements'

const TicketActionForm = ({ forwarded, taken, open, isSupporter, isCreator, ticketId, updateTicket, isForwardedToUser, creatorName }) => {
    const { t } = useTranslation()

    const [value] = useContext(UserContext)
    const [text, setText] = useState(isSupporter ?
`Hallo ${creatorName}



Mit freundlichen Grüßen
${value.first_name} vom Xeonity-Team
                `: "")

    return (
        <>
            {
                (isCreator || isSupporter) &&
                <FormInputField iconName="pencil" name={t("pages.ticketsystem.write") + "..."} inputComponent={
                    <>
                        <Form.Control placeholder={!open && t("pages.ticketsystem.whyOpenAgain")} value={text} onChange={(e) => setText(e.target.value)} as="textarea" rows="7"></Form.Control>
                    </>
                } />
            }
            {
                (isCreator || (isSupporter && !forwarded)) &&
                <OpenCloseAnswerButtons t={t} open={open} ticketId={ticketId} text={text} updateTicket={updateTicket} setText={setText} />
            }
            {
                isSupporter &&
                <ForwardButtons t={t} forwarded={forwarded} ticketId={ticketId} text={text} updateTicket={updateTicket} setText={setText} isOpen={open} />
            }
            {
                ((value.group === "admin" || value.group === "supporter") && open && !isCreator) &&
                <TakeFloatButtons t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} setText={setText} isTaken={taken} isSupporter={isSupporter} />
            }
            {
                forwarded && isForwardedToUser && !isCreator &&
                <>
                    <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="forward_accept" setText={setText} />
                    <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="forward_decline" setText={setText} />
                </>
            }
        </>
    )
}

const ForwardButtons = ({ t, forwarded, ticketId, text, updateTicket, setText, isOpen }) => {
    if (forwarded) {
        return (
            <>
                <hr />
                <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="forward_revoke" setText={setText} />
            </>
        )
    }
    if (isOpen) {
        return (
            <>
                <hr />
                <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="forward" setText={setText} />
            </>
        )
    }
    return null
}

const OpenCloseAnswerButtons = ({ t, open, ticketId, text, updateTicket, setText }) => {
    if (open) {
        return (
            <>
                <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="answer" setText={setText} />
                <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="close" setText={setText} />
            </>
        )
    } else {
        return (
            <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="open" setText={setText} />
        )
    }
}

const TakeFloatButtons = ({ t, ticketId, text, updateTicket, setText, isTaken, isSupporter }) => {
    if (!isTaken) {
        return (
            <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="take" setText={setText} />

        )
    }
    if (isSupporter) {
        return (
            <>
                <hr />
                <ActionButton t={t} ticketId={ticketId} text={text} updateTicket={updateTicket} action="float" setText={setText} />
            </>
        )
    }
    return null
}

const ActionButton = ({ t, ticketId, text, updateTicket, action, disabled, setText }) => {

    const [loading, setLoading] = useState(false)
    const [currentForwardSupporter, setCurrentForwardSupporter] = useState()

    const accessToken = getCookie("access")

    const performAction = () => {
        let performActionBody = { text }

        if (action === "forward") {
            performActionBody = { ...performActionBody, toUser: currentForwardSupporter }
        }
        if (action === "take" || action === "forward_accept" || action === "forward_decline") {
            performActionBody = {}
        }
        apiRequest("POST", "/support/ticketsystem/" + ticketId + "/" + action, performActionBody, () => {
            setLoading(true)
        }, (data) => {
            setLoading(false)
            updateTicket()
            setCurrentForwardSupporter(null)
            setText("")
        }, (errMessage) => {
            setLoading(false)
            swal(t(errMessage), "", "error")
        }, accessToken)
    }

    const [supportersToForward, setSupportersToForward] = useState([])
    const fetchSupporterToForward = () => {
        apiRequest("GET", "/support/ticketsystem/" + ticketId + "/forwardSupporter", {}, () => {
            setLoading(true)
        }, (data) => {
            setLoading(false)
            setSupportersToForward(data.user)
        }, (errMessage) => {
            setLoading(false)
            swal(t(errMessage), "", "error")
        }, accessToken)
    }

    useEffect(() => {
        if (action === "forward")
            fetchSupporterToForward()
    }, [])

    return (
        <>
            {
                (action === "forward") &&
                <SwitchWithArrow as="select" onChange={(elmt) => setCurrentForwardSupporter(elmt.target.value)}>
                    <option value="null">Supporter auswählen</option>
                    {
                        supportersToForward.map(supporter => <option value={supporter._id}>{supporter.username}</option>)
                    }
                </SwitchWithArrow>
            }
            <Button style={{ "margin": "4px" }} onClick={performAction} disabled={disabled}>
                <LoadingIcon show={loading} />
                {t("pages.ticketsystem.actions." + action)}
            </Button>
        </>
    )
}

export default TicketActionForm
