import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import Ticketsystem from '../components/Ticketsystem'

const TicketsystemPage = () => {
    return (
        <>
            <Navigation />
            <Ticketsystem />
        </>
    )
}

export default TicketsystemPage
