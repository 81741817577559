import React from 'react'
import { useState, useEffect } from 'react'
import { LoadingIcon } from '../custom/LoadingIcon'
import apiRequest from "../../axios"
import { convertPriceToString, getCookie } from '../../Utils'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { useContext } from 'react'
import { UserContext } from '../../provider/userProvider'
import { Table } from 'react-bootstrap'
import { SummaryHeading } from '../Forms/FormElements'
import { Button } from 'react-bootstrap'
import swal from '@sweetalert/with-react'
import { useHistory } from 'react-router-dom'

const TransactionList = ({ admin }) => {

    const { t } = useTranslation()

    const [value] = useContext(UserContext)

    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const [transactions, setTransactions] = useState([])

    const history = useHistory()

    const fetchTransactions = async () => {
        setLoading(true)

        const accessToken = getCookie("access")
        let url = "/user/" + value.userId + "/transactions/"
        if (admin)
            url = "/transactions/"
        await apiRequest("get", url, null, () => { }, (data) => {

            setLoading(false)

            setTransactions(data.transactions)

        }, (errMessage) => {

            setLoading(false)
            setError(t(errMessage))

        }, accessToken)
    }

    useEffect(() => {
        fetchTransactions()
    }, [])

    const positiveTransactionTypes = [
        "PREPAID_RECHARGE",
        "PREPAID_RECHARGE_MANUALLY"
    ]

    return (
        <>
            <ErrorMessage>{error}</ErrorMessage>
            <LoadingIcon show={isLoading} />
            {
                !isLoading && (transactions && transactions.length > 0 ? <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Datum</th>
                            <th>Betrag</th>
                            <th>Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.reverse().map((transaction, index) => {
                                const positiv = positiveTransactionTypes.includes(transaction.transactionType)
                                const prefix = positiv ? "+" : "-"
                                const formattedAmount = prefix + convertPriceToString(transaction.amount) + " €"

                                return (
                                    // yellow background for pending transactions
                                    // gray background for canceled transactions
                                    <tr style={transaction.transactionStatus === "PENDING" ? { "background-color": "rgb(237, 211, 97)" } : (transaction.transactionStatus === "RETREATED" || transaction.transactionStatus === "EXPIRED" ? { "background-color": "lightgray" } : {})}>
                                        <td>{index + 1}</td>
                                        <td>{new Date(transaction.date).toLocaleString()}</td>
                                        <td style={{ "color": positiv ? "green" : "red" }}>{formattedAmount}</td>
                                        <td>
                                            {
                                                admin && transaction.transactionStatus === "PENDING"
                                                    ?
                                                    <Button onClick={() => {
                                                        history.push("/adminpanel/transactions/manually/" + transaction._id)
                                                    }}>Bearbeiten</Button>
                                                    :
                                                    <Button onClick={() => swal(
                                                        <div style={{ "textAlign": "left" }}>
                                                            <h2>{t("pages.orders.order_details")}:</h2>
                                                            <SummaryHeading>ID: {transaction._id}</SummaryHeading>
                                                            <SummaryHeading>Typ: {transaction.transactionType}</SummaryHeading>
                                                            <SummaryHeading>Betrag: {convertPriceToString(transaction.amount)}€</SummaryHeading>
                                                            <SummaryHeading>Status: {transaction.transactionStatus}</SummaryHeading>
                                                            <SummaryHeading>Bemerkungen: {transaction.notes}</SummaryHeading>
                                                        </div>
                                                    )}>{t("pages.transactions.transaction_details")}</Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </Table> : t("pages.transactions.noTransactions"))
            }
        </>
    )
}

export default TransactionList
