import React, { useState, useEffect } from "react"
import apiRequest from "../axios"
import { setCookie, getCookie, convertPriceToString } from "../Utils"
import jwt_decode from "jwt-decode"

export const UserContext = React.createContext()

export const UserProvider = (props) => {

    const [value, setValue] = useState({
        loading: true,
        isLoggedIn: false,
        userId: "",
        username: "",
        email: "",
        group: "",
        balance: 0,
        tokenUpdateTimeout: null,

        first_name: "",
        last_name: "",
    })

    const updateData = (toUpdate) => {
        setValue({ ...value, ...toUpdate })
    }

    const fetchUser = async (userId, key) => {
        updateData({loading: true})
        await apiRequest("get", "/user/" + userId, null, () => { }, (data) => {
            updateData({
                loading: false,
                isLoggedIn: true,
                userId: userId,
                username: data.user.username,
                email: data.user.email,
                group: data.user.group,
                balance: convertPriceToString(data.user.balance),
                first_name: data.user.first_name,
                last_name: data.user.last_name
            })
        }, (errMessage) => {
            updateData({
                loading: false
            })
        }, key, false)
    }

    const logout = async () => {
        // logout user from api (deletes refresh token)
        await apiRequest("post", "/auth/logout", {}, () => {}, () => {}, () => {}, getCookie("access"))

        // reset frontend data (also if logout has failed)
        // delete cookie
        setCookie("access", "", -1)

        // stop token refresh interval
        clearInterval(value.tokenUpdateInterval)

        // reset userdata (frontend)
        updateData({
            loading: false,
            isLoggedIn: false,
            userId: "",
            username: "",
            email: "",
            group: "",
            balance: 0,
            tokenUpdateInterval: null,
            first_name: "",
            last_name: ""
        })
    }

    // load user data if access token is set
    const accessToken = getCookie("access")
    useEffect(() => {
        if (!value.loggedIn) {
            // when no access token, then token gets refreshed on wrong call
            // may do better in future commit, because this solution is quite ugly
            let userId = "nouserid"
            if (accessToken) {
                try {
                    userId = jwt_decode(accessToken).sub
                } catch(err) {}
            }
            fetchUser(userId, accessToken)
        } else {
            updateData({ loading: false })
        }
    }, [accessToken])

    const methods = {
        fetchUser,
        logout,
        updateData
    }

    return (
        <UserContext.Provider value={[value, methods]}>
            {props.children}
        </UserContext.Provider>
    )
}