import styled from "styled-components"

export const OrderedItem = styled.li`
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;

    @media (max-width: 500px) {
    margin-left: 0.75rem;
    }
    @media (max-width: 330px) {
    margin-left: 0.25rem;
    }
`