export const configurator = {
    // has to be in "/images/" folder
    "background": "products/game_cloud.jpg",
    "inputs": {
        "cpu": {
            "type": "select",
            "options": {
                "50": 0.75,
                "100": 1.50,
                "150": 2.25,
                "200": 3.00,
                "250": 3.75,
                "300": 4.50,
                "350": 5.25,
                "400": 6.00,
            },
            "defaultoption": "100",
            "showonsummary": true
        },
        "ram": {
            "type": "select",
            "options": {
                "1": 1.50,
                "2": 3.00,
                "3": 4.50,
                "4": 6.00,
                "5": 7.50,
                "6": 9.00,
                "8": 12.00,
                "10": 15.00,
                "12": 18.00,
                "14": 21.00,
                "16": 24.00,
            },
            "defaultoption": "2",
            "showonsummary": true
        },
        "storage": {
            "type": "select",
            "options": {
                "25": 1.00,
                "50": 2.00,
                "75": 3.00,
                "100": 4.00
            },
            "defaultoption": "25",
            "showonsummary": true
        }
    },
    "duration": {
        "daysForInterval": 30,
        "type": "select",
        "options": {
            "10": 1.1,
            "30": 1,
            "60": 1,
            "90": 0.9
        },
        "defaultoption": "30",
    }
}
