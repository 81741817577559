import styled from "styled-components"

export const SectionHeader = styled.p`
    margin: 0 0 5px 0;
    font-size: 18px;
    text-decoration: underline;
`

export const ProductSuspendedText = styled.p`
    color: red;
    font-size: 22px;
`