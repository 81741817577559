import { BrowserRouter as Router, Switch } from "react-router-dom"
import Footer from "./components/Footer"
import Partners from "./components/Partners"
import Subnavigation from "./components/Navigation/Subnavigation"
import Home from './pages/home'
import ProductConfiguraton from "./pages/product_configuration"
import ErrorPage from "./pages/not_found"
import ScrollToTop from "./ScrollToTop"
import RegisterPage from "./pages/register"
import RegisterVerifyPage from "./pages/registerverify"
import LoginPage from "./pages/login"
import ProfilePage from "./pages/profile"
import React from "react"
import { UserProvider } from "./provider/userProvider"
import { CustomRoute, PrivateRoute, PublicRoute } from "./Routes"
import RechargeCreditPage from "./pages/recharge_credit"
import TransactionsPage from "./pages/transactions"
import ProductsPage from "./pages/products"
import AdminPanel from "./pages/admin/adminpanel"
import UserListPage from "./pages/admin/user/user"
import UserManagePage from "./pages/admin/user/usermanage"
import AdminProductListPage from "./pages/admin/products/productlist"
import AdminProductManagePage from "./pages/admin/products/productmanage"
import ProductManagePage from "./pages/productmanage"
import ClaimGameCloudAccountPage from "./pages/claimgamecloudaccount"
import TicketsystemPage from "./pages/ticketsystem"
import TicketPage from "./pages/ticketpage"
import SupportPage from "./pages/support"
import LegalPage from "./pages/legal"
import ResetPasswordPage from "./pages/resetpassword"
import FAQPage from "./pages/faq"
import ChangePasswordPage from "./pages/changePassword"
import AdminOrderListPage from "./pages/admin/order/orderlist"
import ProductReconfigurePage from "./pages/productReconfigure"
import CookieConsentOverlay from "./pages/cookieAccept"
import ReactGA from 'react-ga';
import RouteChangeTracker from './RouteChangeTracker';
import OrdersPage from "./pages/orders"
import { ProductInfoPage } from "./pages/productinfo"
import AdminTransactionListPage from "./pages/admin/transaction/transactions"
import AdminManualTransactionPage from "./pages/admin/transaction/manualtransaction"
import AdminCouponListPage from "./pages/admin/coupons/coupons"
import AdminCouponManagePage from "./pages/admin/coupons/couponmanage"
import AdminCouponCreatePage from "./pages/admin/coupons/couponcreate"

export const context = React.createContext()

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY)
  return (
    <>
      <UserProvider>
        <Subnavigation />
        <Router>
          <ScrollToTop />
          <CookieConsentOverlay />
          <Switch>
            <CustomRoute path="/" component={Home} title="navigation.main.brand_logo_alt" exact />
            <CustomRoute path="/products/:productType" component={ProductConfiguraton} title="pages.products.title" exact />
            <CustomRoute path="/products/:productType/info" component={ProductInfoPage} title="pages.products.info.title" exact />
            <PublicRoute path="/register" component={RegisterPage} title="register.title" exact restricted />
            <CustomRoute path="/register/verify/:verifyToken" component={RegisterVerifyPage} title="register.verify.title" exact />
            <PublicRoute path="/passwordreset/:token" component={ResetPasswordPage} title="pages.passwordReset.passwordReset" exact restricted />
            <PublicRoute path="/login" component={LoginPage} title="login.login" exact restricted />
            <PrivateRoute path="/profile" component={ProfilePage} title="pages.profile.title" exact />
            <PrivateRoute path="/profile/claimgamecloudaccount" component={ClaimGameCloudAccountPage} title="pages.profile.claimgamecloudaccount" exact />
            <PrivateRoute path="/profile/changePassword" component={ChangePasswordPage} title="pages.profile.changePassword" exact />
            <PrivateRoute path="/recharge-credit" component={RechargeCreditPage} title="pages.recharge_credit.recharge_credit" exact />
            <PrivateRoute path="/profile/transactions" component={TransactionsPage} title="pages.transactions.transactions" exact />
            <PrivateRoute path="/profile/orders" component={OrdersPage} title="pages.orders.orders" exact />
            <PrivateRoute path="/profile/products" component={ProductsPage} title="pages.profile.overview_products" exact />
            <PrivateRoute path="/profile/products/:productId" component={ProductManagePage} title="pages.profile.overview_products" exact />
            <PrivateRoute path="/profile/products/:productId/reconfigure" component={ProductReconfigurePage} title="pages.products.product_extend" exact />

            <PrivateRoute path="/adminpanel" whitelistedGroups={["admin"]} component={AdminPanel} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/user" whitelistedGroups={["admin"]} component={UserListPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/user/:userId" whitelistedGroups={["admin"]} component={UserManagePage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/products" whitelistedGroups={["admin"]} component={AdminProductListPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/products/:productId" whitelistedGroups={["admin"]} component={AdminProductManagePage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/orders" whitelistedGroups={["admin"]} component={AdminOrderListPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/transactions" whitelistedGroups={["admin"]} component={AdminTransactionListPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/transactions/manually" whitelistedGroups={["admin"]} component={AdminManualTransactionPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/transactions/manually/:transactionId" whitelistedGroups={["admin"]} component={AdminManualTransactionPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/coupons" whitelistedGroups={["admin"]} component={AdminCouponListPage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/coupons/create" whitelistedGroups={["admin"]} component={AdminCouponCreatePage} title="Adminpanel" exact />
            <PrivateRoute path="/adminpanel/coupons/:code" whitelistedGroups={["admin"]} component={AdminCouponManagePage} title="Adminpanel" exact />

            <CustomRoute path="/support" component={SupportPage} title="pages.support.support" exact />
            <CustomRoute path="/support/faq" component={FAQPage} title="pages.support.channels.faq.faq" exact />
            <PrivateRoute path="/support/ticketsystem/:ticketsystemTab?" component={TicketsystemPage} title="pages.ticketsystem.ticketsystem" exact />
            <PrivateRoute path="/support/ticketsystem/ticket/:ticketId" component={TicketPage} title="pages.ticketsystem.ticketsystem" exact />

            <CustomRoute path="/legal/:legalType" component={LegalPage} title="footer.legal.legal" exact />
            <CustomRoute component={ErrorPage} />
          </Switch>
          <Partners />
          <Footer />
          <RouteChangeTracker />
        </Router>
      </UserProvider>
    </>
  );
}

export default App