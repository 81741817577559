import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from "styled-components"
import { getCookie, setCookie } from '../Utils'
import { Box } from '../components/custom/responsivebox/ReponsiveBoxElements'
import { Link, useLocation } from "react-router-dom"
import reactStringReplace from 'react-string-replace'

const Text = styled.p`
    font-size: 18px;
`

const CookieConsentOverlay = () => {
    const { t } = useTranslation()
    const [cookiesAccepted, setCookiesAccepted] = useState(false)

    const location = useLocation()

    // show if not cookies not accepted yet and NOT on legal pages (has to acces them without accepting)
    if (getCookie("cookieAccept") !== "" || location.pathname.startsWith("/legal")) {
        return null
    }

    return (
        <AbsoluteBackgroundOverlay show={!cookiesAccepted}>
            <Box miniheight="true">
                <h2>{t("cookieconsent.beforeEntering")}</h2>
                <Text>
                    {reactStringReplace(t("cookieconsent.acceptPrivacyPolicy"), "%terms_of_privacy%", () =>
                        <Link to="/legal/terms-of-privacy">{t("footer.legal.privacy")}</Link>
                    )}:
                </Text>
                <Button onClick={
                    () => {
                        setCookie("cookieAccept", true, 365)
                        setCookiesAccepted(true)
                    }
                }>{t("cookieconsent.accept")}</Button>
            </Box>
        </AbsoluteBackgroundOverlay>
    )
}

const AbsoluteBackgroundOverlay = styled.div`
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, .5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: start;
    display: ${({ show }) => show ? "flex" : "none"};
`

export default CookieConsentOverlay
