import React from 'react'
import ConfiguratorForm from '../Forms/configurator'
import {
    useParams,
    Redirect
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResponsiveBox from '../custom/responsivebox/ResponsiveBox'
import products from "../../data/products"

const Configurator = () => {

    let { productType } = useParams()
    const { t } = useTranslation()

    // redirect to error page, if product doesn't exist
    if (!(productType in products)) {
        return <Redirect to={"/unknownpage"} />
    }

    const configuration = products[productType].configuration
    const backgroundImg = require("../../images/" + configuration.background).default

    return (
        <>
            <ResponsiveBox backgroundImg={backgroundImg} key={productType} boxComponent={
                <>
                    <h2>{t("products." + productType + ".title")}</h2>

                    <ConfiguratorForm productType={productType} formConfiguration={configuration} />
                </>
            } />
        </>
    )
}

export default Configurator
