import React, { useContext } from 'react'
import { useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import { Text } from "../../Profile/ProfileElements"
import { BackButton } from '../../custom/Backbutton'
import UserRestrictForm from '../../Forms/userrestrict'
import UserDataEdit from '../../Profile/edituserdata'
import { UserContext } from "../../../provider/userProvider"

const UserManage = () => {

    const valuesToEdit = [
        "username",
        "email",
        "group",
        "gender",
        "first_name",
        "last_name",
        "address_street",
        "address_number",
        "address_zip",
        "address_city",
        "address_region",
        "address_country",
        "phone"
    ]

    const { userId } = useParams()

    const [values] = useContext(UserContext)

    return (
        <>
            <BackButton to="/adminpanel/user" />
            <h1>Benutzerverwaltung</h1>

            <UserDataEdit admin userId={userId} selectedEditableValues={valuesToEdit} />

            <br /><br /><br />
            <hr />

            Restrict user:
            <UserRestrictForm userId={userId} />

            <hr />

            Cooming Soon:
            <Button disabled variant="dark">Kill users session (logout after access token expires)</Button><br /><br />
            <Button disabled variant="dark">Instant logout (only in emergency cases!!! everyone will get logged out!</Button><br /><br />
            <Button disabled variant="dark">Delete user</Button><br /><br />
            <Button disabled variant="dark">restrict user</Button>
        </>
    )
}

export default UserManage