import InfoSite from "../components/InfoSite"
import Navigation from "../components/Navigation/Navigation"
import PasswordResetForm from "../components/Forms/passwordReset"


const ChangePasswordPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} title="pages.profile.changeYourPassword" content={<PasswordResetForm isNormalReset />} />
        </>
    )
}

export default ChangePasswordPage