import React, { useState, useEffect } from 'react'
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import apiRequest from '../../axios';
import { useContext } from 'react';
import { UserContext } from '../../provider/userProvider';
import { LoadingIcon } from '../custom/LoadingIcon';
import { ErrorMessage } from '../custom/ErrorMessage';
import { getCookie } from '../../Utils';
import { Link } from 'react-router-dom';
import products from "../../data/products"

const ProductsList = ({ admin }) => {
    const { t } = useTranslation()
    const [value] = useContext(UserContext)
    let { productType } = useParams()

    let apiRoute = "/user/" + value.userId + "/products"
    let manageRoute = "/profile/products/"
    if (admin) {
        apiRoute = "/products"
        manageRoute = "/adminpanel/products/"
    }

    const productTypes = Object.keys(products)

    const [key, setKey] = useState(productTypes.includes(productType) ? productType : productTypes[0]);

    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [fetchedProducts, setFetchedProducts] = useState([])

    const accessToken = getCookie("access")
    const fetchProducts = () => {
        apiRequest("get", apiRoute, null, () => {
            setLoading(true)
        }, (data) => {
            // on success
            setLoading(false)

            setErrorMessage("")

            setFetchedProducts(data.products)
        }, (errorMessage) => {
            setLoading(false)
            // error
            setErrorMessage(t(errorMessage))
        }, accessToken)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            {
                // show loading animation when loading products
                (loading && <LoadingIcon show={loading} />) ||
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    {
                        productTypes.map((oneOfManyProductTypes) =>
                            <Tab eventKey={oneOfManyProductTypes} title={t("products." + oneOfManyProductTypes + ".title")}>
                                {
                                    (
                                        // when no product with type then show error no product found
                                        !fetchedProducts.some((product) => {
                                            if (product.productType === oneOfManyProductTypes) {
                                                return true
                                            }
                                            return false
                                        }) && <p>{t("pages.products.noproductsoftype")}</p>) ||
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{t("pages.products.product_expire")}</th>
                                                <th>{t("pages.products.product_manage")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                fetchedProducts.map((product) => {
                                                    if (product.productType !== oneOfManyProductTypes) {
                                                        return
                                                    }
                                                    return (
                                                        <tr>
                                                            <td>{product.productId}</td>
                                                            <td>{(new Date(product.expire)).toLocaleDateString() + " " + (new Date(product.expire)).toLocaleTimeString()}</td>
                                                            <td>
                                                                <Link to={manageRoute + product.productId}>
                                                                    <Button>{t("pages.products.product_manage")}</Button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }
                            </Tab>
                        )
                    }
                </Tabs>}
        </>
    );
}

export default ProductsList
