import React from 'react'
import RegisterForm from '../Forms/register'
import InfoSite from '../InfoSite'

const Register = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title="register.register" content={<RegisterForm />} />
        </>
    )
}

export default Register
