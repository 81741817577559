import styled from "styled-components"

export const FAQNode = styled.div`
    width: 100%;
    background-color: white;
    border: 3px solid gray;
    margin-top: 30px;
    margin-bottom: 30px;
`

export const Text = styled.p`
    margin: 0;
`

export const DropdownIconWrapper = styled.div`
    float: right;
`

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
    font-size: 22px;
    background-color: white;
    min-height: 60px;
    cursor: pointer;

    @media screen and (max-width: 576px) {
        font-size: 17px;
    }
`

export const Collapsable = styled.div`
    display: ${({ show }) => show ? "block" : "none"};
    padding: 0 25px;
`

export const CollapsableContent = styled.div`
    border-left: 2px solid black;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-left: 10px;


    @media screen and (max-width: 576px) {
        font-size: 15px;
    }
`