import React from 'react'
import AdminProductManage from '../../../components/Admin/Products/productmanage'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminProductManagePage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide="true" content={
                <AdminProductManage />
            } />
        </>
    )
}

export default AdminProductManagePage
