import { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import apiRequest from "../../../axios"
import { getCookie } from "../../../Utils"
import { BackButton } from "../../custom/Backbutton"
import { ErrorMessage } from "../../custom/ErrorMessage"
import { Text } from "../../Forms/FormElements"

const AdminCouponList = () => {

    const [coupons, setCoupons] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {
        apiRequest("get", "/coupons", {}, () => { }, (data) => {
            setError("")
            setCoupons(data.coupons)
        }, (errMessage) => {
            setError(errMessage)
        }, getCookie("access"))
    }, [])

    return (
        <>
            <BackButton to="/adminpanel" />
            <h1>Couponverwaltung</h1>
            <Link to="coupons/create">
                <Button>Coupon erstellen</Button>
            </Link>
            <Text>Alle Coupons:</Text>
            <ErrorMessage>{error}</ErrorMessage>
            {
                (coupons && <Table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Value</th>
                            <th>used</th>
                            <th>min value</th>
                            <th>expires at</th>
                            <th>only for product type</th>
                            <th>min product duration</th>
                            <th>config min?</th>
                            <th>active?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons.map(entry => {
                            const expireDate = new Date(entry.expire)
                            return <tr>
                                <td>{entry.code}</td>
                                <td>{entry.value}{entry.couponType === "PERCENTAGE" ? "%" : "€"}</td>
                                <td>{entry.used}/{entry.uses}</td>
                                <td>{entry.minOrderValue ? entry.minOrderValue + "€" : "no min value"}</td>
                                <td>{expireDate.toLocaleDateString()} {expireDate.toLocaleTimeString()}</td>
                                <td>{entry.limitedToProductType ? entry.limitedToProductType : "worx @ every product"}</td>
                                <td>{entry.limitedToMinDurationInDays ? entry.limitedToMinDurationInDays : "no such limit"}</td>
                                <td>{entry.limitedToMinConfiguration ? "jep" : "nope"}</td>
                                <td>{entry.active ? "jep" : "nope"}</td>
                                <td><Link to={"/adminpanel/coupons/" + entry.code}><Button>More Info/Edit</Button></Link></td>
                            </tr>
                        }
                        )}
                    </tbody>
                </Table>

                ) ||

                // if no data
                <p>Keine Coupons</p>
            }
        </>
    )
}

export default AdminCouponList