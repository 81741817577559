import React from 'react'
import {
    ProductsWrapper,
    Title,
    Description,
    CustomColumn,
    Icon,
    Heading
} from "./ProductsElements"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row'
import Button from '../../custom/Button'
import LineIcon from "react-lineicons"
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Products = () => {
    const { t } = useTranslation()
    return (
        <>
            <ProductsWrapper id="products">
                <Heading>{t("products.products")}</Heading>
                <Container fluid="md">
                    <Row>
                        <CustomColumn sm="6">
                            <Icon>
                                <LineIcon name="cloud-network" />
                            </Icon>
                            <Title>{t("products.minecraft-server.title")}</Title>
                            <Description>{t("products.minecraft-server.description")}</Description>

                            <Link to="/products/minecraft-server">
                                <Button variant="primary">
                                    <LineIcon name="bolt" />
                                    {t("products.minecraft-server.button")}
                                </Button>
                            </Link>

                        </CustomColumn>

                        <CustomColumn sm="6">
                            <Icon>
                                <LineIcon name="pin" />
                            </Icon>
                            <Title>Coming soon...</Title>

                        </CustomColumn>
                    </Row>
                </Container>
            </ProductsWrapper>
        </>
    )
}

export default Products
