import React, { useState, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import NavItem from 'react-bootstrap/NavItem'
import Nav from 'react-bootstrap/Nav'
import Button from "../../custom/Button"
import LineIcon from "react-lineicons"
import { useTranslation } from 'react-i18next'
import { NavbarFlex, Navbar, NavDropdown, ResponsiveNavbarCollapse } from "./NavigationElements"
import { Link } from "react-router-dom"
import { UserContext } from "../../../provider/userProvider"
import { LoadingIcon } from '../../custom/LoadingIcon'
import DropdownButton from '../../custom/DropdownButton'
import { useHistory } from "react-router-dom"

const Navigation = () => {
    const history = useHistory()
    
    const [value, methods] = useContext(UserContext)

    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    function toggleOpen() {
        if (open)
            return setOpen(false)

        setOpen(true)
    }

    return (
        <>
            <Navbar fixed expand="lg" bg="light">
                <Container fluid="md">
                    <Link className="navbar-brand" to="/">
                        <img src={require("../../../images/logo_transparent.svg").default} alt={t("navigation.main.brand_logo_alt")} width="160" className="d-inline-block align-text-top" />
                    </Link>

                    <Navbar.Toggle onClick={toggleOpen} />

                    <NavbarFlex className={open ? "d-flex" : "d-none"}>

                        <ResponsiveNavbarCollapse>
                            <Nav className="me-auto my-2 my-lg-0">
                                {/*<NavDropdown title={t("navigation.main.navigations.domains.domains")}>
                                    <Link to="/products/domains/info" className={"dropdown-item"}>{t("navigation.main.navigations.domains.domains")}</Link>
    </NavDropdown>*/}
                                <NavDropdown title={t("navigation.main.navigations.server-hosting.server-hosting")}>
                                    <Link to="/products/minecraft-server" className={"dropdown-item"}>{t("navigation.main.navigations.server-hosting.minecraft-server")}</Link>
                                    <Link to="/products/rust-server" className={"dropdown-item"}>{t("navigation.main.navigations.server-hosting.rust-server")}</Link>
                                </NavDropdown>
                                <NavDropdown title={t("navigation.main.navigations.support.support")}>
                                    <Link to="/support" className={"dropdown-item"}>{t("navigation.main.navigations.support.support")}</Link>
                                    <Link to="/support/ticketsystem" className={"dropdown-item"}>{t("navigation.main.navigations.support.ticketsystem")}</Link>
                                </NavDropdown>

                                <NavItem>

                                    {
                                        // if data if loading
                                        (value.loading &&
                                            <Button><LoadingIcon show /></Button>) ||

                                        // if data has loaded and user logged in
                                        (value.isLoggedIn &&
                                            <DropdownButton dropdownEntries={[
                                                {
                                                    "to": "/profile",
                                                    "name": t("navigation.main.profile_dropdown.profile")
                                                },
                                                {
                                                    "to": "/profile/products",
                                                    "name": t("navigation.main.profile_dropdown.products")
                                                },
                                                {
                                                    "to": "/adminpanel",
                                                    "name": t("navigation.main.profile_dropdown.adminpanel"),
                                                    "whitelistedGroups": ["admin"]
                                                },
                                                {
                                                    "name": t("navigation.main.profile_dropdown.logout"),
                                                    "onClick": () => {
                                                        // logout
                                                        methods.logout()

                                                        // redirect to home page
                                                        history.push("/")
                                                    }
                                                }
                                            ]}>
                                                {value.username}
                                            </DropdownButton>) ||

                                        // if user isn't logged in
                                        <Link to="/login">
                                            <Button>
                                                <LineIcon name="user" style={{ "marginRight": "10px" }} />
                                                {t("navigation.main.login-customers")}
                                            </Button>
                                        </Link>
                                    }
                                </NavItem>
                            </Nav>
                        </ResponsiveNavbarCollapse>
                    </NavbarFlex>
                </Container>
            </Navbar>


        </>

    )
}

export default Navigation
