import { useContext, useEffect } from "react"
import { Redirect, Route } from "react-router"
import { LoadingIcon } from "./components/custom/LoadingIcon"
import { UserContext } from "./provider/userProvider"
import ErrorPage from "./pages/not_found"
import { useTranslation } from "react-i18next"

export const PublicRoute = ({component: Component, restricted, ...rest}) => {
    
    const [value] = useContext(UserContext)

    return (
        <CustomRoute {...rest} render={props => (
            value.loading ?
            <>
                <LoadingIcon show />
            </> : value.isLoggedIn && restricted ? <>
            <Redirect to="/profile" />
            </> : <Component {...props} />
        )} />
    )
}

export const PrivateRoute = ({component: Component, whitelistedGroups, ...rest}) => {
    
    const [value] = useContext(UserContext)

    return (<>
        <CustomRoute {...rest} render={props => (
            // while loading show loading animation
            value.loading ? <LoadingIcon show />

            // else check if logged in
            : (
                // when logged in
                value.isLoggedIn ? (

                    // if route is restricted
                    whitelistedGroups ? (
                        // when his group is whitelisted then show page
                        whitelistedGroups.includes(value.group) ? <Component {...props} />

                        // when user isn't allowed show 404 page
                        : <ErrorPage />
                    )
                    // not restricted, show page
                    : <Component {...props} />)
                
                // when not logged in, then redirect to login page
                : <Redirect to={"/login?r=" + encodeURI(window.location.pathname)} />
            )
        )} /></>
    )
}

export const CustomRoute = ({title, ...rest}) => {
        
    const { t } = useTranslation()

    useEffect(() => {
        document.title = t(title)
    }, [title, t])

    return (
        <Route {...rest} />
    )
}