import React from 'react'
import { BackButton } from '../../../components/custom/Backbutton'
import AdminManualTransactionForm from '../../../components/Forms/manualTransaction'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminManualTransactionPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite content={
                <>
                    <BackButton to="/adminpanel/transactions" />
                    <AdminManualTransactionForm />
                </>
            } />
        </>
    )
}

export default AdminManualTransactionPage
