import React, { useContext, useState, useEffect } from 'react'
import Form from "react-bootstrap/Form"
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormButton, FormInputField, RightSide, SwitchWithArrow } from './FormElements'
import apiRequest from "../../axios"
import { getCookie } from '../../Utils'
import swal from 'sweetalert'
import { UserContext } from '../../provider/userProvider'
import { useHistory } from 'react-router-dom'

const CreateTicketForm = () => {
    const { t } = useTranslation()

    const [value] = useContext(UserContext)

    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [subject, setSubject] = useState([null, null])

    const [error, setErrorMsg] = useState("")

    return (
        <>
            <ErrorMessage>{error}</ErrorMessage>
            <FormInputField iconName="bolt" name={t("pages.ticketsystem.title")} inputComponent={
                <Form.Control onChange={e => setTitle(e.target.value)} placeholder={t("pages.ticketsystem.title")} />
            } />
            <SelectProductButton
                t={t}
                setSubject={setSubject}
                setErrorMsg={setErrorMsg}
                userId={value.userId}
                subject={subject}
            />
            <FormInputField iconName="pencil-alt" name={t("pages.ticketsystem.text")} inputComponent={
                <Form.Control as="textarea" onChange={e => setText(e.target.value)} placeholder={t("pages.ticketsystem.text")} />
            } />
            <CreateTicketButton t={t} title={title} text={text} subject={subject} setErrorMsg={setErrorMsg} />
        </>
    )
}

const SelectProductButton = ({ t, setSubject, setErrorMsg, userId, subject }) => {

    const [productsLoading, setProductsLoading] = useState(true)

    const [fetchedProducts, setFetchedProducts] = useState([])

    const accessToken = getCookie("access")
    const fetchProducts = () => {
        apiRequest("get", "/user/" + userId + "/products", null, () => { }, (data) => {
            // on success
            setProductsLoading(false)

            setErrorMsg("")

            setFetchedProducts(data.products)
        }, (errorMessage) => {
            setProductsLoading(false)
            // error
            setErrorMsg(t(errorMessage))
        }, accessToken)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        (productsLoading && <LoadingIcon show />) ||
        <FormInputField iconName="angle-double-down" name={t("pages.ticketsystem.chooseProduct")} inputComponent={
            <SwitchWithArrow as="select" onChange={event => {
                const productType = event.target[event.target.selectedIndex].getAttribute('data-producttype')
                const productId = event.target[event.target.selectedIndex].getAttribute('data-productId')
                setSubject([productType, productId])
            }} placeholder={t("pages.ticketsystem.text")}>
                <option>{t("pages.ticketsystem.noProduct")}</option>
                {
                    fetchedProducts.map(product => <option data-producttype={product.productType} data-productId={product.productId}>{t("products." + product.productType + ".title")} (#{product.productId})</option>)
                }
            </SwitchWithArrow>
        } />
    )
}

const CreateTicketButton = ({ t, title, text, setErrorMsg, subject }) => {

    const history = useHistory()

    const [showLoading, setShowLoading] = useState(false)

    async function createTicket() {

        if (!title || !text || subject.length === 0) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        const [productType, productId] = subject

        const accessToken = getCookie("access")

        // do api request
        apiRequest("post", "/support/ticketsystem", {
            title,
            text,
            productType,
            productId
        }, () => {
            setShowLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setShowLoading(false)
            history.push("/support/ticketsystem/ticket/" + data.ticket.shortId)
            swal(t("pages.ticketsystem.created"), t("pages.ticketsystem.createdInfo"), "success")
        }, (errMessage) => {
            setShowLoading(false)
            return setErrorMsg(t(errMessage))
        }, accessToken)

    }

    return (
        <RightSide>
            <LoadingIcon show={showLoading} />
            <FormButton onClick={createTicket} disabled={showLoading}>{t("pages.ticketsystem.createTicket")}</FormButton>
        </RightSide>
    )
}

export default CreateTicketForm
