import { OrderedItem } from "./LegalElements"

const TermsOfWithdrawal = () => {
    return (
        <>
            <h1>Widerrufsbelehrung</h1>
            <br />
            <h2>§1 Widerrufsrecht des Kunden als Verbraucher</h2>
            Verbrauchern steht ein Widerrufsrecht nachfolgender Maßgabe zu, wobei Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.

            <h2>§2 Widerrufsbelehrung</h2>

            <ol>
                <OrderedItem>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</OrderedItem>
                <OrderedItem>Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</OrderedItem>
                <OrderedItem>Falls sie innerhalb der Widerrufsfrist einen Vertrag widerrufen und die Bereitstellung bereits erfolgt oder erfolgt ist, haben sie lediglich Recht auf Erstattung des Betrages der Restlaufzeit. Die Laufzeit wird zur Berechnung des Erstattungsbetrags auf den Tag abgerundet.</OrderedItem>
                <OrderedItem>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br /><br />

                    Xeonity GbR <br />
                    Bischof-Albert-Weg, 12 <br />
                    D-27612 Loxstedt <br />
                    E-Mail: support@xeonity.net <br /><br />

                    mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                </OrderedItem>
            </ol>

            <h2>§3 Ausschlussgründe</h2>

            <ol>
                <li>Das Widerrufsrecht erlischt, sobald die Bereitstellung des Produktes vollendet ist.</li>
                <li>Bei Domainbestellungen besteht kein Widerrufsrecht.</li>
            </ol>

            <h2>§4 Widerrufsfolgen</h2>

            <ol>
                <li>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</li>
                <li>Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</li>
                <li>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.<br />

                    Sie tragen die unmittelbaren Kosten der Rücksendung der Waren, inklusive möglicher anfallender Zahlungsanbieterkosten.
                </li>
            </ol>

            <h2>§5 Widerrufen</h2>
            <ol>
                <li>Wenn sie einen Vertrag widerrufen wollen, können sie das Musterformular (Anhang 1) ausfüllen und es uns zusenden.</li>
                <li>Der Widerruf kann formlos erfolgen, das nachfolgende Formular ist lediglich eine Vorlage. Eine Widerrufserklärung muss aber eindeutig sein und alle im Musterformular enthaltenen Informationen enthalten.</li>
            </ol>

            <br /><br />
            Anhang 1: Widerrufsformular<br />
            An:<br />
            Xeonity GbR<br />
            Bischof-Albert-Weg, 12<br />
            D-27612 Loxstedt<br />
            E-Mail: support@xeonity.net<br />
            <br />
            Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)<br />
            <br />
            _____________________________________________________<br />
            <br />
            Bestellt am (*)/erhalten am (*)<br />
            <br />
            __________________<br />
            <br />
            Name des/der Verbraucher(s)<br />
            <br />
            _____________________________________________________<br />
            <br />
            Anschrift des/der Verbraucher(s)<br />
            <br />
            <br />
            _____________________________________________________<br />
            <br />
            Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br />
            <br />
            __________________<br />
            <br />
            Datum<br />
            <br />
            __________________<br />
            <br />
            <br />
            (*) Unzutreffendes streichen.<br />
            <br />
            <br />
            Stand der Belehrung: Dez. 2022 | Änderungen vorbehalten


        </>
    )
}

export default TermsOfWithdrawal