import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import apiRequest from '../../axios'
import { UserContext } from '../../provider/userProvider'
import { getCookie } from '../../Utils'
import Button from '../custom/Button'
import { LoadingIcon } from '../custom/LoadingIcon'
import { ProfileInfoHeading, ProfileInformationWrapper, Part } from './ProfileElements'
import { Text } from "../Forms/FormElements"

const ProfileInformation = () => {

    const { t } = useTranslation()

    const [value] = useContext(UserContext)

    const [isProductCheckLoading, setIsProductCheckLoading] = useState(true)
    const [hasProducts, setHasProducts] = useState(false)

    useEffect(() => {
        const accessToken = getCookie("access")
        apiRequest("get", "/user/" + value.userId + "/products", null, () => { }, (data) => {
            // on success
            setIsProductCheckLoading(false)

            if (data.products.length > 0) {
                setHasProducts(true)
            }
        }, (errorMessage) => {
            setIsProductCheckLoading(false)
        }, accessToken)
    }, [])

    return (
        <>
            <ProfileInformationWrapper>
                <Part>
                    <ProfileInfoHeading>{t("pages.profile.overview")}:</ProfileInfoHeading>
                    <Text>{t("generally.username")}: {value.username}</Text>
                    <Text>{t("generally.email")}: {value.email}</Text>
                    <Text>{t("generally.credit")}: {value.balance} €</Text>
                    <Link to="/recharge-credit">
                        <Button style={{ "margin": "7px auto auto 7px" }}>{t("pages.recharge_credit.recharge_credit")}</Button>
                    </Link>
                    <Link to="/profile/transactions">
                        <Button style={{ "margin": "7px auto auto 7px" }}>{t("pages.transactions.transactions")}</Button>
                    </Link>
                    <Link to="/profile/orders">
                        <Button style={{ "margin": "7px auto auto 7px" }}>{t("pages.orders.orders")}</Button>
                    </Link>
                    {
                        // disabled, because when gamecloud account cant be created on register, register is cancelled
                        /*<Link to="/profile/claimGameCloudAccount">
                            <Button style={{ "margin": "7px auto auto 7px" }}>{t("pages.profile.claimgamecloudaccount")}</Button>
                        </Link>*/
                    }
                    <Link to="/profile/changePassword">
                        <Button style={{ "margin": "7px auto auto 7px" }}>{t("pages.profile.changePassword")}</Button>
                    </Link>
                </Part>
                <Part>
                    <ProfileInfoHeading>{t("pages.profile.overview_products")}:</ProfileInfoHeading>
                    {
                        isProductCheckLoading ? <LoadingIcon show /> :
                            (
                                hasProducts ? <Link to="/profile/products">
                                    <Button>
                                        {t("navigation.main.profile_dropdown.products")}
                                    </Button>
                                </Link> : <Text>{t("pages.profile.noproducts")}</Text>
                            )
                    }
                </Part>
            </ProfileInformationWrapper>
        </>
    )
}

export default ProfileInformation
