import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { FormInputField, SwitchWithArrow } from "../Forms/FormElements"

const QuickForm = ({ params, handleChange, defaultValues }) => {
    const { t } = useTranslation()

    return (
        <>
            {
                Object.keys(params).map(key =>
                    params[key].type === "select" ?
                        <SwitchWithArrow as="select" onChange={handleChange(key)} defaultValue={defaultValues[key]} placeholder={"leon stinkt"} style={{ "cursor": "pointer" }}>
                            {
                                Object.keys(params[key].options).map(val => <option value={val}>{params[key].options[val]}</option>)
                            }
                        </SwitchWithArrow>
                        :
                        <FormInputField name={t(params[key].name)} inputComponent={
                            <Form.Control onChange={handleChange(key)} id={key} type={params[key].type} placeholder={t(params[key].name)} value={defaultValues[key]} />
                        } />)
            }
        </>
    )
}

export default QuickForm