import styled from "styled-components"
import BootstrapNavbar from "react-bootstrap/Navbar"
import BootstrapNavDropdown from "react-bootstrap/NavDropdown"

export const Navbar = styled(BootstrapNavbar)`
    background-color: #ffffff !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

export const NavDropdown = styled(BootstrapNavDropdown)`
    font-family: "Lato-Black", sans-serif;
    font-weight: 800;
    text-transform: uppercase;
`

export const NavbarFlex = styled.div`
    @media (min-width: 992px) {
        display: flex !important;
    }
`

export const ResponsiveNavbarCollapse = styled(BootstrapNavbar.Collapse)`
    @media (max-width: 992px) {
        width: 90vw;
    }
`