import Form from "react-bootstrap/Form"
import styled from "styled-components"
import Button from 'react-bootstrap/Button'
import LineIcon from "react-lineicons"

export const InputLabel = styled(Form.Label)`
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
`

export const SwitchWithArrow = styled(Form.Control)`
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    :disabled{
        opacity: 0.5;
        background-color: lightgray;
    }
`

export const FormButton = styled(Button)`
    // style removed
`

// two styles for inline text input and button component (text input on the left, button on the right)
export const FormButtonRightForTextField = styled(Button)`
    display: inline-block;
    border-radius: 0 5px 5px 0;
    margin-top: -3px;
`
export const FormTextInputLeftForButton = styled(Form.Control)`
    display: inline-block;
    border-radius: 5px 0 0 5px;
`


export const RightSide = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    float: right;
`

export const LeftSide = styled(RightSide)`
    float: left;
    display: block;
`

export const PriceField = styled.p`
    float: left;
    margin-top: 15px;
    font-size: 1.2rem;
`

export const FormInputField = ({ iconName, name, inputComponent, small }) => {
    return (
        <>
            <InputLabel style={small ? {"width":"500px", "display": "inline-block", "margin": "22px"} : {}}>
                <LineIcon name={iconName} style={{ "margin": "0 7px", "fontSize": "105%" }} />
                {name}
                <div style={{"marginTop": "7px"}}>
                {inputComponent}
                </div>
            </InputLabel>
        </>
    )
}

export const SummaryHeading = styled.p`
    margin-bottom: 0;
`

export const SummaryPoint = styled.p`
    margin: 0 0 0 20px;
`

export const SuccessIconWrapper = styled.div`
    text-align: center;
    font-size: 80px;
    color: rgb(40, 200, 60);
`

export const ErrorIconWrapper = styled(SuccessIconWrapper)`
    color: rgb(240, 40, 20);
`

export const Text = styled.p`
    font-size: 20px;
    margin: 0;
`

export const ShowEditToggleElement = ({ editMode, what, value, handleChange, disabled, small }) => {
    return (
        <>
            {
                (editMode &&
                    <FormInputField small={small} name={what} inputComponent={
                        <Form.Control disabled={disabled} onChange={handleChange} id={what} type="text" defaultValue={value} placeholder={what} />
                    } />) ||
                <Text>{what}: {value}</Text>

            }

        </>
    )
}

export const ShowEditToggleElementSmall = styled(ShowEditToggleElement)`
    max-width: 500px;
    background: red;
`