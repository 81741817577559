import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import RegisterVerify from '../components/Register/verify'

const RegisterPage = () => {
    // TODO check if already logged in

    return (
        <>
            <Navigation />
            <RegisterVerify />
        </>
    )
}

export default RegisterPage
