import React from 'react'
import { useTranslation } from 'react-i18next'
import InfoSection from '../components/Home/InfoSection'
import Navigation from '../components/Navigation/Navigation'

const ErrorPage = () => {
    const { t } = useTranslation()
    return (
        <>

            <Navigation />
            <InfoSection id="prepaid-system"
                topLine={t("pages.notFound.error404")}
                headLine={t("pages.notFound.pageNotFound")}
                description={t("pages.notFound.description")}
                buttonLabel={t("pages.notFound.support")}
                buttonToPath="/support"
                imgStart="true"
                img={require("../images/illustrations/404_not_found.svg").default}
                alt="contact"
                big
            />

        </>
    )
}

export default ErrorPage
