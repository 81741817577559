import { OrderedItem } from "./LegalElements"

const TermsOfService = () => {
    return (
        <>
            <h1>Nutzungsbedingungen/ Allgemeine Geschäftsbedingungen (AGBs)</h1>
            <br />
            <h2>§1 Geltung gegenüber Unternehmern und Begriffsdefinitionen</h2>
            <ol>
                <OrderedItem>Die nachfolgenden Allgemeinen Geschäftsbedingungen (nachfolgend auch kurz formuliert AGB oder AGBs oder Nutzungsbedingungen) gelten für alle Lieferungen zwischen uns und einem Verbraucher in ihrer zum Zeitpunkt der Bestellung gültigen Fassung. </OrderedItem>
                <OrderedItem>Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB). </OrderedItem>
                <OrderedItem>Eine Bereitstellung ist gleich einer Dienstleistung nach (§ 611 BGB). </OrderedItem>
            </ol>

            <h2>§2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes</h2>
            <ol>
                <OrderedItem>Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren Internetshop https://www.xeonity.net . </OrderedItem>
                <OrderedItem>Im Falle des Vertragsschlusses kommt der Vertrag mit<br /><br />


                    Xeonity GbR <br />
                    Bischof-Albert-Weg, 12 <br />
                    D-27612 Loxstedt <br />

                    <br />
                    zustande. </OrderedItem>
                <OrderedItem>Die Präsentation der Waren in unserem Internetshop stellen kein rechtlich bindendes Vertragsangebot unsererseits dar, sondern sind nur eine unverbindliche Aufforderung an den Verbraucher, Waren zu bestellen. Mit der Bestellung der gewünschten Ware gibt der Verbraucher ein für ihn verbindliches Angebot auf Abschluss eines Kaufvertrages ab.</OrderedItem>
                <OrderedItem>Bei Eingang einer Bestellung in unserem Internetshop gelten folgende Regelungen: Der Verbraucher gibt ein bindendes Vertragsangebot ab, indem er die in unserem Internetshop vorgesehene Bestellprozedur erfolgreich durchläuft.<br />
                    Die Bestellung erfolgt in folgenden Schritten:<br /><br />

                    (Sollten Sie die Website auf eine andere Sprache gestellt haben, können die jeweiligen Buttontexte in der anderen Sprache sein)<br />
                    <ol>
                        <OrderedItem>Anmeldung im Internetshop nach Registrierung und Eingabe der Anmeldeangaben (Benutzername/E-Mail-Adresse und Passwort)</OrderedItem>
                        <OrderedItem>Auswahl des gewünschten Produkts</OrderedItem>
                        <OrderedItem>Eventuell: Konfiguration des Produkts; Klick auf “Weiter”</OrderedItem>
                        <OrderedItem>Prüfung der Angaben in der Zusammenfassung</OrderedItem>
                        <OrderedItem>Verbindliche Absendung der Bestellung durch Anklicken des Buttons „Kostenpflichtig bestellen“ bzw. „Kaufen“ bzw. “Kauf abschließen”.</OrderedItem>
                    </ol>
                </OrderedItem>
                <OrderedItem>Speicherung des Vertragstextes bei Bestellungen über unseren Internetshop: Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und ein Verweis auf unsere AGB per E-Mail zu. Die AGB können Sie jederzeit auch unter https://xeonity.net/legal/terms-of-service einsehen. Ihre vergangenen Bestellungen können Sie in unserem Kunden-Bereich unter "Bestellungen” einsehen.</OrderedItem>
            </ol>

            <h2>§3 Preise, Versandkosten, Zahlung, Fälligkeit</h2>
            <ol>
                <OrderedItem>Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und sonstige Preisbestandteile. Hinzu kommen etwaige Versandkosten.</OrderedItem>
                <OrderedItem>Der Verbraucher hat die Möglichkeit der Zahlung per Guthaben. Guthaben kann aufgeladen werden über den Button “Guthaben aufladen” in der Profilübersicht.</OrderedItem>
                <OrderedItem>Hat der Verbraucher die Zahlung per Vorkasse gewählt, so verpflichtet er sich, den Kaufpreis unverzüglich nach Vertragsschluss zu zahlen.</OrderedItem>
            </ol>

            <h2>§4 Lieferung bzw. Bereitstellung</h2>
            <ol>
                <OrderedItem>Bei digitalen Produkten im Shop ist anstelle einer Lieferung von einer Bereitstellung die Rede, da diese nicht physikalisch ausgeliefert werden, sondern lediglich digital zur Verfügung gestellt werden.</OrderedItem>
                <OrderedItem>Sofern wir dies in der Produktbeschreibung nicht deutlich anders angegeben haben, können alle Artikel von uns sofort bereitgestellt werden. Die jeweilige Bereitstellungsdauer kann der Produktinformation entnommen werden.</OrderedItem>
                <OrderedItem>Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Sache geht auch beim Versendungskauf erst mit der Übergabe der Sache an den Käufer auf diesen über.</OrderedItem>
                <OrderedItem>Bei Nichteinhaltung von Gesetzen oder unserer Nutzungsbedingungen, behalten wir uns das Recht vor Kundenkonten zu sperren und die jeweiligen Produkte der Kunden zu suspendieren. U. a. ist das Hosting von jeglicher Pornografie, sowie urheberrechtlich geschütztem Material nicht erlaubt.</OrderedItem>
            </ol>

            <h2>§5 Eigentumsvorbehalt</h2>
            <ol>
                <OrderedItem>Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung des Kaufpreises vor.</OrderedItem>
            </ol>

            <h2>§6 Gewährleistung</h2>
            <ol>
                <OrderedItem>Es gelten die gesetzlichen Gewährleistungsregelungen.</OrderedItem>
            </ol>


            <h2>§7 Vertragssprache</h2>
            <ol>
                <OrderedItem>Als Vertragssprache steht ausschließlich Deutsch zur Verfügung..</OrderedItem>
            </ol>



            <h2>§8 Kundendienst</h2>
            <ol>
                <OrderedItem>Unser Kundendienst für Fragen, Reklamationen und Beanstandungen steht Ihnen werktags von 8:30 Uhr bis 17:30 Uhr unter<br /><br />

                    Telefon: 04703/4460-600<br />
                    Telefax: 0703/4460-599<br />
                    E-Mail: support@xeonity.net<br /><br />

                    zur Verfügung.</OrderedItem>
            </ol>
            <br /><br />
            <p>Stand der AGB: Dez. 2022 | Änderungen vorbehalten</p>
        </>
    )
}

export default TermsOfService
