import React from 'react'
import { Redirect, useParams } from 'react-router'

// legal content from /data/legal
import Imprint from "../../data/legal/imprint"
import TermsOfPrivacy from '../../data/legal/termsofprivacy'
import TermsOfService from '../../data/legal/termsofservice'
import TermsOfWithdrawal from '../../data/legal/termsofwithdrawal'

const Legal = () => {

    const legalParams = {
        "imprint": Imprint,
        "terms-of-privacy": TermsOfPrivacy,
        "terms-of-service": TermsOfService,
        "terms-of-withdrawal": TermsOfWithdrawal
    }

    const { legalType } = useParams()
    if (!Object.keys(legalParams).includes(legalType)) {
        return <Redirect to={"/unknownpage"} />
    }
    const LegalContent = legalParams[legalType]
    return (
        <>
        <LegalContent />
        </>
    )
}

export default Legal
