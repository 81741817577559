import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { UserContext } from '../../provider/userProvider';
import CreateTicketForm from '../Forms/createTicket';
import TicketsList from './ticketslist';

const TicketOverview = ({ admin }) => {
    const { t } = useTranslation()
    const [value] = useContext(UserContext)
    let { ticketsystemTab } = useParams()

    const ticketsystemTabs = [
        "my-tickets",
        "create",
        "alltickets",
        "forwardedtickets",
        "recievedTickets",
        "takenTickets",
        "floatingTickets"
    ]

    const [currentTab, setCurrentTab] = useState(ticketsystemTabs.includes(ticketsystemTab) ? ticketsystemTab : ticketsystemTabs[0]);

    return (
        <>
            {
                <Tabs
                    id="controlled-tab-example"
                    activeKey={currentTab}
                    onSelect={(tab) => setCurrentTab(tab)}
                    className="mb-3"
                >
                    <Tab eventKey={ticketsystemTabs[0]} title={t("pages.ticketsystem.yourTickets")}>
                        <TicketsList userId={value.userId} />
                    </Tab>
                    <Tab eventKey={ticketsystemTabs[1]} title={t("pages.ticketsystem.createTicket")}>
                        <CreateTicketForm />
                    </Tab>
                    {
                        // show all tickets if has permission
                        (value.group === "admin" || value.group === "supporter") &&
                        <Tab eventKey={ticketsystemTabs[2]} title={t("pages.ticketsystem.allTickets")}>
                            <TicketsList supporter />
                        </Tab>
                    }
                    {
                        // show forwarded tickets if has permission
                        (value.group === "admin" || value.group === "supporter") &&
                        <Tab eventKey={ticketsystemTabs[3]} title={t("pages.ticketsystem.forwardedTickets")}>
                            <TicketsList forwarded />
                        </Tab>
                    }
                    {
                        // show recieved tickets if has permission
                        (value.group === "admin" || value.group === "supporter") &&
                        <Tab eventKey={ticketsystemTabs[4]} title={t("pages.ticketsystem.recievedTickets")}>
                            <TicketsList recieved />
                        </Tab>
                    }
                    {
                        // show taken tickets if has permission
                        (value.group === "admin" || value.group === "supporter") &&
                        <Tab eventKey={ticketsystemTabs[5]} title={t("pages.ticketsystem.takenTickets")}>
                            <TicketsList taken />
                        </Tab>
                    }
                    {
                        // show floating tickets if has permission
                        (value.group === "admin" || value.group === "supporter") &&
                        <Tab eventKey={ticketsystemTabs[6]} title={t("pages.ticketsystem.floatingTickets")}>
                            <TicketsList floating />
                        </Tab>
                    }
                </Tabs>}
        </>
    );
}

export default TicketOverview
