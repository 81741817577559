import React from 'react'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'
import Ticket from '../components/Ticketsystem/ticket'

const TicketPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} content={<Ticket />} wide="true" />
        </>
    )
}

export default TicketPage
