const Imprint = () => {
    return (
        <>
            <h1>Impressum</h1>
            <p>XEONITY GbR<br />
                Bischof-Albert-Weg 12<br />
                27612 Loxstedt<br />
                Tel: 04703/4460-600<br />
                Fax: 04703/4460-950<br />
                <a href="mailto:info@xeonity.net">info@xeonity.net</a>
            </p>
            <p><strong>Vertreten durch geschäftsführende Gesellschafter:</strong></p>
            <p>Lars Friedrich</p>
            <p>Donald Forster</p>
        </>
    )
}

export default Imprint