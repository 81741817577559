import React from 'react'
import { BackButton } from '../components/custom/Backbutton'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'
import ProductManage from '../components/Products/productmanage'

const ProductManagePage = () => {
    return (
        <>
            <Navigation />
            <InfoSite content={
                <>
                    <BackButton to="/profile/products" />
                    <h1>Produktverwaltung</h1>
                    <ProductManage />
                </>
            } />
        </>
    )
}

export default ProductManagePage
