import React, { useContext, useEffect, useState } from 'react'
import {
    HeaderWrapper,
    Title,
    Features,
    FeatureItem,
    IconWrapper,
    Promotion,
    PromotionButtonFirst,
    PromotionButtonSecond,
    InformationWrapper
} from "./HeaderElements"
import Container from "react-bootstrap/Container"
import LineIcon from "react-lineicons"
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import apiRequest from '../../../axios';
import { UserContext } from '../../../provider/userProvider';
import { getCookie } from '../../../Utils';

const Header = () => {
    const { t } = useTranslation();
    return (
        <>
            <InformationBar />
            <HeaderWrapper>
                <Container fluid="md">
                    <Title>{t("pages.home.header.title")}</Title>
                    <h4 className="subtitle">{t("pages.home.header.subtitle")}</h4>

                    <Features>
                        <FeatureItem>
                            <IconWrapper>
                                <LineIcon name="checkmark" />
                            </IconWrapper>
                            {t("pages.home.header.features.feature_1")}
                        </FeatureItem>
                        <FeatureItem>
                            <IconWrapper>
                                <LineIcon name="checkmark" />
                            </IconWrapper>
                            {t("pages.home.header.features.feature_2")}
                        </FeatureItem>
                        <FeatureItem>
                            <IconWrapper>
                                <LineIcon name="checkmark" />
                            </IconWrapper>
                            {t("pages.home.header.features.feature_3")}
                        </FeatureItem>
                    </Features>

                    <Promotion>
                        <ScrollLink to="products" smooth>
                            <PromotionButtonFirst>{t("pages.home.header.promotion.getProduct")}</PromotionButtonFirst>
                        </ScrollLink>
                        <Link to="/support">
                            <PromotionButtonSecond>{t("pages.home.header.promotion.support")}</PromotionButtonSecond>
                        </Link>
                    </Promotion>
                </Container>
            </HeaderWrapper>
        </>
    )
}

const InformationBar = () => {

    const { t } = useTranslation()

    const [value] = useContext(UserContext)
    const [message, setMessage] = useState("")

    useEffect(() => {
        if (value.isLoggedIn)
            apiRequest("GET", "/user/" + value.userId + "/notifications", {}, () => { }, (data) => {
                setMessage(t("api." + data.informationMessages[0]))
            }, (errMessage) => {
                // dont know what to do here
            }, getCookie("access"))
    }, [value])

    if (message)
        return (
            <InformationWrapper>{message}</InformationWrapper>
        )

    return null
}

export default Header

