import React, { useState, useContext } from 'react'
import Form from "react-bootstrap/Form"
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormButton, FormInputField, LeftSide, RightSide } from './FormElements'
import apiRequest from "../../axios"
import { setCookie } from '../../Utils'
import { Link, useHistory } from "react-router-dom";
import { UserContext } from '../../provider/userProvider'
import jwt_decode from "jwt-decode"
import swal from 'sweetalert'
import { useLocation } from "react-router-dom";

const LoginForm = () => {
    const { t } = useTranslation()
    const history = useHistory();

    const search = useLocation().search
    let redirectUrl = decodeURI(new URLSearchParams(search).get("r"))
    if (redirectUrl == "null") {
        redirectUrl = null
    }

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const [showLoading, setShowLoading] = useState(false)

    const [error, setErrorMsg] = useState("")

    // user data
    const methods = useContext(UserContext)[1]

    async function submitLogin() {

        if (!email || !password) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        // do api request
        apiRequest("post", "/auth/login", {
            email,
            password
        }, () => {
            setShowLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setShowLoading(false)

            setCookie("access", data.tokens.access, 1)

            // decode jwt to get user id and expire
            const { sub: userId } = jwt_decode(data.tokens.access)

            methods.fetchUser(userId, data.tokens.access)

            // redirect to main page or page specified in url
            history.push(redirectUrl || "/")

        }, (errMessage) => {
            setShowLoading(false)

            if (errMessage === "api.auth.login.emailNotVerified") {
                //swal(t(errMessage), t("login.resendEmailVerifyEmail"))
                swal({
                    text: t(errMessage),
                    buttons: {
                        resendMail: {
                            text: t("login.sendAgain"),
                            value: "resendMail",
                            closeModal: false
                        },
                        delete: {
                            text: t("generally.cancel"),
                            closeModal: true
                        }
                    },
                })
                    .then((action) => {
                        if (action !== "resendMail")
                            return
                        swal({
                            text: t("login.typeEmailForSendingVerifyEmailAgain"),
                            content: "input",
                            button: {
                                text: t("login.sendAgain"),
                                closeModal: false,
                            },
                        })
                            .then(email => {
                                if (!email)
                                    throw null

                                return apiRequest("post", "/auth/resendVerificationEmail", {
                                    email
                                }, () => {
                                    setShowLoading(true)
                                    setErrorMsg(null)
                                }, (data) => {
                                    setShowLoading(false)
                                    swal(t("api." + data.message), "", "success")
                                }, (errMessage) => {
                                    setShowLoading(false)
                                    swal(t(errMessage), "", "error")
                                })
                            })
                            .catch(() => { })
                    })
                    .catch(() => { })
            }

            return setErrorMsg(t(errMessage))
        })

    }

    const openForgotPassword = () => {
        swal({
            text: t("pages.passwordReset.enterEmail"),
            content: "input",
            button: {
                text: t("pages.passwordReset.sendMail"),
                closeModal: false,
            },
        })
            .then(email => {
                if (!email)
                    throw null

                return apiRequest("post", "/auth/sendResetPasswordEmail", {
                    email
                }, () => {
                    setShowLoading(true)
                    setErrorMsg(null)
                }, (data) => {
                    setShowLoading(false)
                    swal(t("api." + data.message), "", "success")
                }, (errMessage) => {
                    setShowLoading(false)
                    swal(t(errMessage), "", "error")
                })
            })
    }

    return (
        <>
            <ErrorMessage>{error}</ErrorMessage>
            <FormInputField iconName="postcard" name={t("login.emailOrUsername")} inputComponent={
                <Form.Control type="email" onChange={e => setEmail(e.target.value)} placeholder={t("login.emailOrUsername")} />
            } />
            <FormInputField iconName="key" name={t("generally.password")} inputComponent={
                <Form.Control type="password" onChange={e => setPassword(e.target.value)} placeholder={t("generally.password")} />
            } />

            <LeftSide>
                <Link to="/register">{t("login.noAccountQuestion")}</Link><br />
                <a href="#" onClick={openForgotPassword}>{t("login.forgotPassword")}</a>
            </LeftSide>
            <RightSide>
                <LoadingIcon show={showLoading} />
                <FormButton onClick={submitLogin} disabled={showLoading}>{t("login.login")}</FormButton>
            </RightSide>
        </>
    )
}

export default LoginForm
