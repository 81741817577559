import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import Profile from '../components/Profile'

const ProfilePage = () => {
    return (
        <>
            <Navigation />
            <Profile />
        </>
    )
}

export default ProfilePage
