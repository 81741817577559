import React from 'react'
import AdminOrderList from '../../../components/Admin/Orders/orderlist'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminOrderListPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite content={
                <AdminOrderList />
            } />
        </>
    )
}

export default AdminOrderListPage
