import styled from "styled-components"

export const TicketInformationWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`

export const TicketInformationHeading = styled.p`
    font-size: 26px;
    margin: 0 0 16px 0;
`

export const TicketInformationPoint = styled.p`
    font-size: 20px;
    //padding-left: 30px;
    margin: 0;
`

export const TicketTitle = styled.span`
    font-size: 24px;
    padding-left: 10px;
`

export const TicketActionMessage = styled.p`
    word-break: break-all;
    font-size: 18px;
    padding-left: 30px;
    margin: 10px 0 10px 20px;
    border-left: 2px solid gray;
    white-space: pre-line;
`

export const TicketActionWrapper = styled.div`

`


export const TicketActionInfo = styled.p`
    font-size: 20px;
    margin: 0
`
