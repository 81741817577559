import { BackButton } from "../../custom/Backbutton"
import OrderList from "../../Orders/orders"
import { Text } from "../../Forms/FormElements"

const AdminOrderList = () => {

    return (
        <>
            <BackButton to="/adminpanel" />
            <h1>Bestellverwaltung</h1>
            <Text>Alle Bestellungen:</Text>
            <OrderList admin />
        </>
    )
}

export default AdminOrderList