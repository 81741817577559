import React from 'react'
import { useTranslation } from 'react-i18next'
import ResponsiveBox from './custom/responsivebox/ResponsiveBox'

const InfoSite = ({ backgroundImg, title, content, wide }) => {
    const { t } = useTranslation()

    // default background
    if (!backgroundImg) {
        backgroundImg = require("../images/products/kvm_server.jpg").default
    }
    return (
        <>
            <ResponsiveBox wide={wide} backgroundImg={backgroundImg} boxComponent={
                <>
                    <h2>{t(title)}</h2>

                    {content}
                </>
            } />
        </>
    )
}

export default InfoSite
