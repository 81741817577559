import React from 'react'
import InfoSite from '../InfoSite'
import TransactionList from './transactions'

const Transactions = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title={"pages.transactions.transactions"} content={<TransactionList />} />
        </>
    )
}

export default Transactions
