import React from 'react'
import { Button } from 'react-bootstrap'

const ManagePage = () => {
    return (
        <>
            <p>Minecraft-Server managen hier</p>
            <Button onClick={
                () => window.location = process.env.REACT_APP_GAMECLOUD_URL
            }>Zur GameCloud</Button>
        </>
    )
}

export default ManagePage
