import React from 'react'
import InfoSite from '../InfoSite'
import TicketOverview from './ticketoverview'

const Ticketsystem = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} content={<TicketOverview />} />
        </>
    )
}

export default Ticketsystem
