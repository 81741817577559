import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { RightSide } from './FormElements'
import apiRequest from "../../axios"
import { getCookie } from '../../Utils'
import swal from '@sweetalert/with-react'
import { ConfigurationInput } from './configurator'
import { Button } from 'react-bootstrap'

const ClaimGameCloudAccountForm = () => {
    const { t } = useTranslation()

    const [password, setPassword] = useState()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const accessToken = getCookie("access")
    const submitClaimRequest = async () => {
        
        if (!password) {
            return setError(t("forms.errors.fillField"))
        }

        // do api request
        apiRequest("post", "/user/creategamecloudaccount", {
            password
        }, () => {
            setLoading(true)
            setError(null)
        }, (data) => {
            setLoading(false)
            setError("")
            swal(t("api." + data.message), "", "success")
        }, (errMessage) => {
            setLoading(false)
            return setError(t(errMessage))
        }, accessToken)
    }

    return (
        <>
                <ErrorMessage>{error}</ErrorMessage>
                <ConfigurationInput
                    onChangeMethod={(elmt) => setPassword(elmt.target.value)}
                    name={t("generally.password")}
                    iconname="bolt"
                    label={t("pages.profile.enterPasswordForGamecloudAccount") + ":"}
                    password
                />
                <RightSide>
                    <Button onClick={submitClaimRequest} disabled={loading}><LoadingIcon show={loading} />{t("generally.create")}</Button>
                </RightSide>
        </>
    )
}

export default ClaimGameCloudAccountForm
