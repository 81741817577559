import axios from "axios"
import { setCookie } from "./Utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  withCredentials: true
});

const apiRequest = (method, route, data, initFct, successFct, errorFct, key = null, redirectWhenForbidden = true, redirectUrl = "") => {
  initFct()

  const controller = new AbortController()

  axiosInstance({
    method,
    url: process.env.REACT_APP_API_URL + route,
    data,
    headers: { 'Authorization': 'Bearer ' + key },
    signal: controller.signal
  })
    .then(answer => {
      const response = answer.data
      const { data, success, err } = response
      if (!success) {
        return errorFct(err.message)
      }
      return successFct(data)
    })
    .catch(e => {
      const res = e.response
      
      if(!res) { 
        return errorFct("generally.tooManyRequests")
      }

      // check if answer is valid (mainly to check if api is responding in right format)
      if (!res.headers["content-type"].includes("application/json")) {
        return errorFct("invalid api response type")
      }

      // network error (basicly every 5xx error)
      if (!res) {
        return errorFct("generally.errors.generalError")
      }

      // server error
      if (res.status.toString().charAt(0) === "5") {
        return errorFct("generally.errors.generalError")
      }

      // too many requests
      if (res.status === 429) {
        return errorFct("generally.errors.slowDown")
      }

      // unauthorized
      if (res.status === 401) {
        // refresh session
        return apiRequest("post", "/auth/refresh", {}, () => {}, (data) => {
          // session refreshed
          // updating access token
          const newAccessToken = data.tokens.access
          setCookie("access", newAccessToken, 1)          

          // try initial api call again
          apiRequest(method, route, data, initFct, successFct, errorFct, newAccessToken)
        }, () => {
          errorFct("generally.errors.loginExpired")
          // error, user has manually resign in
          if (redirectWhenForbidden && window.location.pathname !== "/login")
            window.location.href = "/login?r=" + encodeURI(window.location.pathname)
        })
      }

      // forbidden
      if (res.status === 403) {
        return errorFct("generally.errors.forbidden")
      }

      // other client error
      if (res.status.toString().charAt(0) === "4") {
        return errorFct("api." + res.data.err.message)
      }

      return errorFct("api." + res.data.err.message)

    })
    return controller
}

export default apiRequest