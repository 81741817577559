import { SummaryPoint } from "./components/Forms/FormElements";
import products from "./data/products"

export const convertPriceToString = price => {
    return price.toFixed(2).toString().replace(/\./g, ',')
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function generateSummaryFromConfiguration(t, productType, configuration) {
    const summaryPoints = Object.keys(configuration).map(
        (confKey) => {
            // dont show if product configuration also dont show on ordersummary
            if (!products[productType].configuration.inputs[confKey].showonsummary)
                return

            return <SummaryPoint>
                {
                    t("products." + productType + ".inputs." + confKey + ".name") +
                    ": " +
                    configuration[confKey] +
                    " " +
                    t("products." + productType + ".inputs." + confKey + ".unit")
                }
            </SummaryPoint>
        }
    )
    return summaryPoints
}