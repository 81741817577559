import React from 'react'
import CouponManage from '../../../components/Admin/Coupons/coupon'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminCouponManagePage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide content={
                <CouponManage />
            } />
        </>
    )
}

export default AdminCouponManagePage
