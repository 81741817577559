import React from 'react'
import FAQ from '../components/FAQ'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'

const FAQPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} title="pages.faq.faq" content={<FAQ />} wide />
        </>
    )
}

export default FAQPage
