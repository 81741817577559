import { BackButton } from "../../custom/Backbutton"
import ProductManage from "../../Products/productmanage"

const AdminProductManage = () => {

    return (
        <>
            <BackButton to="/adminpanel/user" />
            <h1>Produktverwaltung</h1>
            <ProductManage admin />
        </>
    )
}

export default AdminProductManage