import React, { useState } from 'react'
import Form from "react-bootstrap/Form"
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormButton, FormInputField, InputLabel, RightSide } from './FormElements'
import apiRequest from "../../axios"
import { getCookie } from '../../Utils'

const RechargeCreditForm = () => {
    const { t } = useTranslation()

    const [amount, setAmount] = useState()
    const [agbAndWithdrawalAccepted, setAgbAndWithdrawalAccepted] = useState(false)

    const [showLoading, setShowLoading] = useState(false)

    const [error, setErrorMsg] = useState("")

    async function submitRechargeCredit() {

        if (!amount) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        if (!agbAndWithdrawalAccepted) {
            return setErrorMsg(t("order.must_accept_agb_and_withdrawal"))
        }

        // do api request
        const accessToken = getCookie("access")
        apiRequest("post", "/payments", {
            amount,
            redirectUrl: window.location.origin + "/profile"
        }, () => {
            setShowLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setShowLoading(false)

            // redirect to payment page
            window.location.href = data.paymentUrl

        }, (errMessage) => {
            setShowLoading(false)
            return setErrorMsg(t(errMessage))
        }, accessToken)

    }

    return (
        <>
            <ErrorMessage>{error}</ErrorMessage>
            <FormInputField iconName="euro" name={t("pages.recharge_credit.amount_of_money")} inputComponent={
                <Form.Control type="number" onChange={e => setAmount(e.target.value)} placeholder={t("pages.recharge_credit.amount_of_money")} />
            } />
            <InputLabel>
                <Form.Check onChange={(aa) => setAgbAndWithdrawalAccepted(aa.target.checked)} type="checkbox" label={t("legal.termsOfServiceAndTermsOfWithdrawalAccept")} />
            </InputLabel>
            <RightSide>
                <LoadingIcon show={showLoading} />
                <FormButton onClick={submitRechargeCredit} disabled={showLoading}>{t("generally.next")}</FormButton>
            </RightSide>
        </>
    )
}

export default RechargeCreditForm
