import React from 'react'
import UserManage from '../../../components/Admin/User/user'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const UserManagePage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide="true" content={
                <UserManage />
            } />
        </>
    )
}

export default UserManagePage
