import React from 'react'
import AdminTransactionList from '../../../components/Admin/Transactions/transactionlist'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminTransactionListPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite content={
                <AdminTransactionList />
            } />
        </>
    )
}

export default AdminTransactionListPage
