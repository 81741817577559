import React from 'react'
import ClaimGameCloudAccountForm from '../Forms/claimgamecloudaccount'
import InfoSite from '../InfoSite'

const ClaimGameCloudAccount = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} content={<ClaimGameCloudAccountForm />} />
        </>
    )
}

export default ClaimGameCloudAccount
