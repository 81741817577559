import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import apiRequest from "../../axios"
import { getCookie } from "../../Utils"
import { LoadingIcon } from "../custom/LoadingIcon"

const TicketsList = ({ supporter, userId, forwarded, recieved, taken, floating }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)

    const [fetchedTickets, setFetchedTickets] = useState([])

    let path = ""
    if (supporter)
        path = "/support/ticketsystem"
    else if (forwarded)
        path = "/support/ticketsystem/forwarded"
    else if (recieved)
        path = "/support/ticketsystem/recieved"
    else if (taken)
        path = "/support/ticketsystem/taken"
    else if (floating)
        path = "/support/ticketsystem/floating"
    else
        path = "/user/" + userId + "/tickets"

    const accessToken = getCookie("access")
    const fetchAllTickets = () => {
        apiRequest("GET", path, null, () => { }, (data) => {
            // on success
            setLoading(false)

            setFetchedTickets(data.tickets)
        }, (errorMessage) => {
            setLoading(false)
        }, accessToken)
    }

    useEffect(() => {
        fetchAllTickets()
    }, [])

    if (loading) {
        return <LoadingIcon show />
    }

    if (fetchedTickets.length === 0) {
        return <p>{t("pages.ticketsystem.noTickets")}</p>
    }
    return (
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t("pages.ticketsystem.title")}</th>
                    <th>{t("generally.createdAt")}</th>
                    <th>{t("pages.ticketsystem.status")}</th>
                    <th>{t("pages.ticketsystem.details")}</th>
                </tr>
            </thead>
            <tbody>
                {
                    fetchedTickets.map((ticket) =>
                        <tr>
                            <td>{ticket.shortId}</td>
                            <td>{ticket.title}</td>
                            <td>{(new Date(ticket.createdAt)).toLocaleDateString() + " " + (new Date(ticket.createdAt)).toLocaleTimeString()}</td>
                            <td>{ticket.open ? t("pages.ticketsystem.actions_finished.open") : t("pages.ticketsystem.actions_finished.close")}</td>
                            <td><Link to={"/support/ticketsystem/ticket/" + ticket.shortId}><Button>{t("pages.ticketsystem.details")}</Button></Link></td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    )
}

export default TicketsList