import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { BackButton } from "../../custom/Backbutton"
import { Text } from "../../Forms/FormElements"
import TransactionList from "../../Transactions/transactions"

const AdminTransactionList = () => {

    return (
        <>
            <BackButton to="/adminpanel" />
            <h1>Transaktionsverwaltung</h1>
            <Link to="manually">
                <Button>Transaktion erstellen</Button>
            </Link>
            <Text>Alle Transaktionen:</Text>
            <TransactionList admin />
        </>
    )
}

export default AdminTransactionList