import React, { useState } from 'react'
import Form from "react-bootstrap/Form"
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '../custom/ErrorMessage'
import { LoadingIcon } from '../custom/LoadingIcon'
import { FormButton, FormInputField, InputLabel, LeftSide, RightSide, SuccessIconWrapper } from './FormElements'
import apiRequest from "../../axios"
import { ToggleShowDiv } from '../custom/ToggleShowDiv'
import { SuccessMessage } from '../custom/SuccessMessage'
import { Link } from "react-router-dom"
import LineIcon from "react-lineicons"

const RegisterForm = () => {
    const { t } = useTranslation()

    const [username, setUserName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [password2, setPassword2] = useState()
    const [legal, setLegal] = useState(false)
    const [afterForm, setAfterForm] = useState(false)

    function toggleLegal() {
        if (legal) {
            return setLegal(false)
        }
        setLegal(true)
    }

    const [showLoading, setShowLoading] = useState(false)

    const [error, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")

    async function submitRegister() {

        if (!username || !email || !password || !password2) {
            return setErrorMsg(t("forms.errors.fillAll"))
        }

        // check if passwords are equal
        if (password !== password2) {
            return setErrorMsg(t("register.errors.passwordsDontMatch"))
        }

        // check if terms of legal are accepted
        if (!legal) {
            return setErrorMsg(t("generally.errors.acceptTermsOfPrivacy"))
        }

        // do api request
        apiRequest("post", "/auth/register", {
            email,
            username,
            password
        }, () => {
            setShowLoading(true)
            setErrorMsg(null)
        }, (data) => {
            setShowLoading(false)
            setAfterForm(true)
            return setSuccessMsg(t("api." + data.message))
        }, (errMessage) => {
            setShowLoading(false)
            return setErrorMsg(t(errMessage))
        })

    }

    return (
        <>
            <ToggleShowDiv show={!afterForm}>
                <ErrorMessage>{error}</ErrorMessage>
                <FormInputField iconName="postcard" name={t("generally.email")} inputComponent={
                    <Form.Control type="email" onChange={e => setEmail(e.target.value)} placeholder={t("generally.email")} />
                } />
                <FormInputField iconName="user" name={t("generally.username")} inputComponent={
                    <Form.Control type="text" onChange={e => setUserName(e.target.value)} placeholder={t("generally.username")} />
                } />
                <FormInputField iconName="key" name={t("generally.password")} inputComponent={
                    <Form.Control type="password" onChange={e => setPassword(e.target.value)} placeholder={t("generally.password")} />
                } />
                <FormInputField iconName="key" name={t("register.password_repeat")} inputComponent={
                    <Form.Control type="password" onChange={e => setPassword2(e.target.value)} placeholder={t("register.password_repeat")} />
                } />

                <InputLabel>
                    <Form.Check onChange={toggleLegal} type="checkbox" label={t("legal.privacyaccept")} />
                </InputLabel>

                <LeftSide><Link to="/login">{t("login.alreadyAnAccount")}</Link></LeftSide>
                <RightSide>
                    <LoadingIcon show={showLoading} />
                    <FormButton onClick={submitRegister} disabled={showLoading}>{t("register.register")}</FormButton>
                </RightSide>
            </ToggleShowDiv>
            <ToggleShowDiv show={afterForm}>
                <SuccessIconWrapper>
                    <LineIcon name="checkmark-circle" />
                </SuccessIconWrapper>
                <SuccessMessage>{successMsg}</SuccessMessage>
            </ToggleShowDiv>
        </>
    )
}

export default RegisterForm
