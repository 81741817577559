import ManagePageDomains from "./products/domains/managepage"
import InfoPageDomains from "./products/domains/infopage"

//const Managepage_kvmserver = require("./products/kvm-server/managepage").default
const ManagepageMinecraftserver = require("./products/minecraft-server/managepage").default
const ManagepageRustserver = require("./products/rust-server/managepage").default

const Products = {
    /*"kvm-server": {
        "configuration": require("./products/kvm-server/config").configurator,
        "managepage": (props = {}) => <Managepage_kvmserver {...props} />
    },*/
    "minecraft-server": {
        "configuration": require("./products/minecraft-server/config").configurator,
        "managepage": (props = {}) => <ManagepageMinecraftserver {...props} />
    },
    "rust-server": {
        "configuration": require("./products/rust-server/config").configurator,
        "managepage": (props = {}) => <ManagepageRustserver {...props} />
    }
    /*"domains": {
        "configuration": require("./products/domains/config").configurator,
        "managepage": (props = {}) => <ManagePageDomains {...props} />,
        "infopage": (props = {}) => <InfoPageDomains {...props} />
    }*/
}

export default Products