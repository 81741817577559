import React from 'react'
import {
    PartnersWrapper,
    PartnerItem,
    PartnerIconCol
} from "./PartnersElements"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Partners = () => {
    return (
        <>
            <PartnersWrapper>
                <Container fluid="md">
                    <Row>
                        <PartnerIconCol sm>
                            <PartnerItem src={require("../../images/partners/proxmox.png").default} alt="Proxmox"/>
                        </PartnerIconCol>

                        <PartnerIconCol sm>
                            <PartnerItem style={{"width": "90px"}} src={require("../../images/partners/intel.png").default} alt="Intel"/>
                        </PartnerIconCol>

                        <PartnerIconCol sm>
                            <PartnerItem src={require("../../images/partners/ripe-ncc.png").default} alt="Ripe NCC"/>
                        </PartnerIconCol>

                        <PartnerIconCol sm>
                            <PartnerItem src={require("../../images/partners/samsung.png").default} alt="Samsung"/>
                        </PartnerIconCol>
                    </Row>
                </Container>
            </PartnersWrapper>
        </>
    )
}

export default Partners
