import React from 'react'
import Header from '../components/Home/Header'
import Products from '../components/Home/Products'
import InfoSection from '../components/Home/InfoSection'
import Navigation from '../components/Navigation/Navigation'
import { useTranslation } from 'react-i18next'
import { Divider } from '../components/Home/InfoSection/InfoElements'

const Home = () => {

    const { t } = useTranslation()

    return (
        <>
            <Navigation />
            <Header />
            <InfoSection id="prepaid-system"
                topLine={t("pages.home.info.prepaid.topline")}
                headLine={t("pages.home.info.prepaid.heading")}
                description={t("pages.home.info.prepaid.subtitle")}
                buttonLabel={t("pages.home.info.prepaid.button")}
                buttonToPath="/recharge-credit"
                imgStart="true"
                img={require("../images/illustrations/cheap.svg").default}
                alt="cheap" />
            <Divider />
            <InfoSection id="low-latency"
                topLine={t("pages.home.info.lowlatency.topline")}
                headLine={t("pages.home.info.lowlatency.heading")}
                description={t("pages.home.info.lowlatency.subtitle")}
                buttonLabel={t("pages.home.info.lowlatency.button")}
                buttonToPath="/products/minecraft-server"
                imgStart="false"
                img={require("../images/illustrations/lowlatency.svg").default}
                alt="low latency" />
            <Divider />
            <InfoSection id="prepaid-system"
                topLine={t("pages.home.info.customerservice.topline")}
                headLine={t("pages.home.info.customerservice.heading")}
                description={t("pages.home.info.customerservice.subtitle")}
                buttonLabel={t("pages.home.info.customerservice.button")}
                buttonToPath="/support"
                imgStart="true"
                img={require("../images/illustrations/contact.svg").default}
                alt="contact" />
            <Products />
        </>
    )
}

export default Home
