import { Col } from "react-bootstrap"
import styled from "styled-components"

export const PartnersWrapper = styled.div`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-align: center;
    padding-top: calc(1.34375rem + 1.125vw);
    padding-bottom: calc(1.34375rem + 1.125vw);

    @media (min-width: 1200px) {
        padding-top: 2.1875rem;
        padding-bottom: 2.1875rem;
    }
`

export const PartnerItem = styled.img`
    width: 160px;
    margin: 0 auto;
    filter: brightness(0);
    opacity: 0.3;
    transition: 450ms ease-in-out;

    &:hover {
        filter: brightness(1);
        opacity: 1;
        transform: scale(1.02)
    }

    @media (max-width: 576px) {
        margin: 25px;
        width: 130px;
    }

    @media (max-width: 350px) {
        margin: 20px;
    }
`

export const PartnerIconCol = styled(Col)`
    display: flex;
    align-items: center;
`