import styled from "styled-components"
import Col from "react-bootstrap/Col"
import BootstrapRow from "react-bootstrap/Row"
import { Link } from "react-router-dom"

export const FooterWrapper = styled.div`
background-image: linear-gradient(to right, var(--brand_color_primary), var(--brand_color_primary_lighter));
    padding: 50px 0;
`

export const Title = styled.p`
    font-family: "Lato-Bold", sans-serif;
    color: #ffffff;
    font-size: 20px;
`

export const Copyright = styled.p`
    font-family: "Lato-Bold", sans-serif;
    font-size: 1.0625rem;
    font-weight: 600;
    color: #f1f1f1;

    @media (max-width: 576px) {
        text-align: center;
    }
`

export const Logo = styled.img`
width: 13.125rem;
    margin-bottom: calc(1.28125rem + 0.375vw);

    @media (min-width: 1200px) {
        margin-bottom: 1.5625rem;
    }

    @media (max-width: 576px) {
        text-align: center;
    }
    
`

export const Description = styled.p`
    color: #e0ffff;
    width: 80%;

    @media (max-width: 576px) {
        width: 100%;
    }
`

export const StyledLink = styled(Link)`
    display: block;
    font-family: "Lato-Regular", sans-serif;
    text-decoration: none;
    color: #e0ffff;
    margin-right: 0.1875rem;
    transition: ease-in-out 250ms;

    &:hover {
        margin-right: 0.3125rem;
    }
`

export const Contact = styled.p`
    display: block;
    font-family: "Lato-Regular", sans-serif;
    text-decoration: none;
    color: #e0ffff;
`

export const Divider = styled.hr`
    color: #ffffff;
`

export const Column = styled(Col)`
    @media (max-width: 576px) {
        margin-top: 30px;
    }
`

export const Row = styled(BootstrapRow)`
    @media (max-width: 576px) {
        text-align: center;
    }
`