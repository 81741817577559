import React from 'react'
import Navigation from '../components/Navigation/Navigation'
import RechargeCredit from '../components/RechargeCredit'

const RechargeCreditPage = () => {
    return (
        <>
            <Navigation />
            <RechargeCredit />
        </>
    )
}

export default RechargeCreditPage
