import React from "react"
import { useTranslation } from "react-i18next"
import { SupportHeading, SupportInformation, SupportChannelButton, SupportChannelButtonWrapper } from "./SupportElements"
import swal from '@sweetalert/with-react'
import { Link } from "react-router-dom"

const Support = () => {
    const { t } = useTranslation()

    const popupInfo = (channelName) => () => {
        let concreteContact = ""
        switch (channelName) {
            case "whatsapp":
                concreteContact = process.env.REACT_APP_SUPPORT_WHATSAPP
                break
            case "teamspeak":
                concreteContact = process.env.REACT_APP_SUPPORT_TEAMSPEAK
                break
            case "discord":
                concreteContact = <a href={process.env.REACT_APP_SUPPORT_DISCORD}>{process.env.REACT_APP_SUPPORT_DISCORD}</a>
                break
            case "phone":
                concreteContact = <a href={"tel:" + process.env.REACT_APP_SUPPORT_PHONE}>{process.env.REACT_APP_SUPPORT_PHONE}</a>
                break
            case "email":
                concreteContact = <a href={"mailto:" + process.env.REACT_APP_SUPPORT_EMAIL}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
                break
            default:
                concreteContact = channelName
                break
        }
        swal(
            <div>
                <h1>{t("pages.support.channels." + channelName + "." + channelName)}</h1>
                <p>{t("pages.support.channels." + channelName + ".howToContact")} {concreteContact}</p>
            </div>
        )
    }

    return (
        <>
            <SupportHeading>{t("pages.support.support")}</SupportHeading>
            <SupportInformation>{t("pages.support.description")}</SupportInformation>
            <SupportChannelButtonWrapper>
                <Link to="/support/faq">
                    <SupportChannelButton>{t("pages.support.channels.faq.faq")}</SupportChannelButton>
                </Link>
                <SupportChannelButton onClick={popupInfo("whatsapp")}>{t("pages.support.channels.whatsapp.whatsapp")}</SupportChannelButton>
                {/*<SupportChannelButton onClick={popupInfo("discord")}>{t("pages.support.channels.discord.discord")}</SupportChannelButton>*/}
                <SupportChannelButton onClick={popupInfo("phone")}>{t("pages.support.channels.phone.phone")}</SupportChannelButton>
                <SupportChannelButton onClick={popupInfo("email")}>{t("pages.support.channels.email.email")}</SupportChannelButton>
                <SupportChannelButton onClick={popupInfo("teamspeak")}>{t("pages.support.channels.teamspeak.teamspeak")}</SupportChannelButton>
                <Link to="/support/ticketsystem">
                    <SupportChannelButton>{t("pages.support.channels.ticketsystem.ticketsystem")}</SupportChannelButton>
                </Link>
            </SupportChannelButtonWrapper>
        </>
    )
}

export default Support