import React from 'react'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'
import Support from '../components/Support'

const SupportPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} content={<Support />} wide="true" />
        </>
    )
}

export default SupportPage
