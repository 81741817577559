import React from 'react'
import { Link } from 'react-router-dom'
import Button from "../../custom/Button"

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img
} from "./InfoElements"

const InfoSection = ({ id, imgStart, topLine, headLine, description, buttonLabel, buttonToPath, img, alt, big }) => {
    return (
        <>
            <InfoContainer id={id}>
                <InfoWrapper big={big}>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading>{headLine}</Heading>
                                {(() => description.split('\n').map(str => <Subtitle key={str}>{str}</Subtitle>))()}
                                <BtnWrap>
                                    <Link to={buttonToPath}>
                                        <Button>{buttonLabel}</Button>
                                    </Link>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
