import styled from "styled-components"
import BootstrapButton from 'react-bootstrap/Button'

export default styled(BootstrapButton)`
    background-color: ${({ colorType }) => {
        if (!colorType)
            colorType = "primary"
        return "var(--brand_color_" + colorType + ")"
    }} !important;
    
    &:hover {
        background-color: ${({ colorType }) => {
            if (!colorType)
                colorType = "primary"
            return "var(--brand_color_" + colorType + "_hover)"
        }} !important;
    }
`