import React from 'react'
import Login from '../components/Login'
import Navigation from '../components/Navigation/Navigation'

const LoginPage = () => {
    // TODO check if already logged in

    return (
        <>
            <Navigation />
            <Login />
        </>
    )
}

export default LoginPage
