import { Button } from "react-bootstrap"
import styled from "styled-components"

export const SupportHeading = styled.h1`
    text-align: center;
    font-size: 52px;

    @media (max-width: 576px) {
        font-size: 36px;
    }
`

export const SupportInformation = styled.p`
    text-align: center;
    font-size: 32px;

    @media (max-width: 576px) {
        font-size: 22px;
    }
`

export const SupportChannelButtonWrapper = styled.div`
    text-align: center;
`

export const SupportChannelButton = styled(Button)`
    margin: 10px;
    font-size: 24px;
`