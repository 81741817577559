import React, { useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FormInputField } from '../../../components/Forms/FormElements'
import styled from "styled-components"
import { LoadingIcon } from '../../../components/custom/LoadingIcon'
import { ErrorMessage } from '../../../components/custom/ErrorMessage'
import apiRequest from '../../../axios'
import { getCookie } from '../../../Utils'
import isValidDomain from "is-valid-domain"
import { Link } from 'react-router-dom'
import products from "../../../data/products"

const DomainWrapper = styled.div`
    max-width: 600px;
    margin: auto;
`

const SearchButton = styled(Button)`
    border-radius: 0 10px 10px 0;
    margin-top: -3.5px;
`

const DomainInput = styled(Form.Control)`
    border-radius: 10px 0 0 10px;
`

const TableInfoRow = styled.tr`
    text-align: center;
`

const InfoPageDomains = () => {
    const { t } = useTranslation()

    const [domain, setDomain] = useState("")

    const [fetchedDomains, setFetchedDomains] = useState({})
    const [domainsLoading, setDomainsLoading] = useState(0) // amount of domains which are loading
    const [errorMsg, setErrorMsg] = useState("")

    const [lastTimeout, setLastTimeout] = useState()
    const [fetchingDomains, setFetchingDomains] = useState([])

    const availableTlds = Object.keys(products["domains"].configuration.inputs["tld"].options)

    const isDomainAvailable = async (domainName, tld) => {
        return new Promise((resolve) => {
            const requestController = apiRequest("post", "/products/domains/available", {
                duration: 365,
                configuration: {
                    tld,
                    domainName
                }
            }, () => {

            }, (data) => {
                // on success


                setFetchingDomains(prevFetchedDomains => {
                    return prevFetchedDomains.filter(item => {
                        return item !== requestController
                    })
                })
                resolve(data.available)
            }, (errorMessage) => {
                setFetchingDomains(prevFetchedDomains => {
                    return prevFetchedDomains.filter(item => {
                        return item !== requestController
                    })
                })
                resolve(false)
            }, getCookie("access"))

            setFetchingDomains(prevFetchedDomains => ([...prevFetchedDomains, requestController]))
        })
    }

    const searchDomains = () => {
        // check if domain has exactly ONE dot. isValidDomain() also returns true with trailing dot.
        if ((domain.match(/\./g) || []).length !== 1 || !isValidDomain(domain, { "subdomain": false })) {
            setErrorMsg(t("products.domains.errors.invalidDomain"))
            return
        }

        const [domainName, selectedTld] = domain.split(".")

        setErrorMsg("")

        cancelDomainRequests()
        setFetchedDomains({})

        const fetchDomain = (domainName, tld) => {
            if (!availableTlds.includes(tld)) {
                setFetchedDomains(prevFetchedDomains => {
                    prevFetchedDomains[domainName + "." + tld] = "unknowntld"
                    return { ...prevFetchedDomains }
                })
                return
            }
            setFetchedDomains(prevFetchedDomains => {
                prevFetchedDomains[domainName + "." + tld] = "loading"
                return { ...prevFetchedDomains }
            })

            isDomainAvailable(domainName, tld).then(available => {
                setFetchedDomains(prevFetchedDomains => {
                    prevFetchedDomains[domainName + "." + tld] = available ? "available" : "notavailable"
                    return { ...prevFetchedDomains }
                })
            })
        }

        // fetch wanted domain
        fetchDomain(domainName, selectedTld)

        // fetch alternative domains
        const requestDelay = 800
        let i = 0
        const otherTlds = availableTlds.filter(tld => tld !== selectedTld)
        setDomainsLoading(otherTlds.length)
        function delayRequest() {
            setLastTimeout(setTimeout(async () => {
                const tld = otherTlds[i]


                fetchDomain(domainName, tld)

                if (i < otherTlds.length - 1) {
                    delayRequest()
                }
                i++
                setDomainsLoading(otherTlds.length - i)
            }, requestDelay))
        }
        delayRequest()
    }

    const cancelDomainRequests = () => {
        // prevent timeout from sending new requests
        clearTimeout(lastTimeout)

        // abort current fetch requests
        fetchingDomains.forEach(fetchingDomainController => fetchingDomainController.abort())
    }

    return (
        <>
            <DomainWrapper>
                <ErrorMessage>{errorMsg}</ErrorMessage>
                <FormInputField iconName="world" name={t("products.domains.your_domain")} inputComponent={
                    <>
                        <DomainInput style={{ "width": "80%", "display": "inline-block" }} type="text" onChange={e => setDomain(e.target.value)} placeholder={t("products.domains.inputs.domainName.name")} />
                        <SearchButton style={{ "width": "20%", "display": "inline-block" }} onClick={searchDomains}>{t("generally.search")}</SearchButton>
                    </>
                } />
                {
                    Object.keys(fetchedDomains).length > 0 &&
                    <Table>
                        <thead>
                            <tr>
                                <th>{t("products.domains.infopage.domainName")}</th>
                                <th>{t("products.domains.infopage.availability")}</th>
                                <th>{t("generally.order")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(fetchedDomains).map(domain => {
                                    // first domain is wanted one
                                    let prefix = <></>
                                    if (Object.keys(fetchedDomains)[0] === domain) {
                                        prefix = <TableInfoRow>
                                            <th colspan="3">{t("products.domains.infopage.yourRequestedDomain")}</th>
                                        </TableInfoRow>
                                    }
                                    if (Object.keys(fetchedDomains)[1] === domain) {
                                        prefix = <TableInfoRow>
                                            <th colspan="3">{t("products.domains.infopage.otherSuggestions")}</th>
                                        </TableInfoRow>
                                    }
                                    const available = fetchedDomains[domain] === "available"
                                    const loading = fetchedDomains[domain] === "loading"
                                    const unknowntld = fetchedDomains[domain] === "unknowntld"

                                    const [domainName, tld] = domain.split(".")

                                    return (
                                        <>
                                            {prefix}
                                            <tr style={{ "opacity": loading || available ? "1" : "0.5" }}>
                                                <td>{domain}</td>
                                                <td>{loading ? <LoadingIcon show /> : unknowntld ? t("products.domains.infopage.notSupported") : available ? t("products.domains.infopage.available") : t("products.domains.infopage.notAvailable")}</td>
                                                <td><Link to={"/products/domains?tld=" + tld + "&domainName=" + domainName}><Button disabled={!available}>{t("generally.order")}</Button></Link></td>
                                            </tr>
                                        </>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                }
                <p style={{ "display": domainsLoading > 0 ? "block" : "none" }}><LoadingIcon show /> {t("products.domains.infopage.moreDomainsLoading")}</p>
            </DomainWrapper>
        </>
    )
}

export default InfoPageDomains
