import React from 'react'
import LineIcon from "react-lineicons"
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BackButton = ({ to }) => {
    return (
        <>
            <StyledLink to={to}>
                <LineIcon name="arrow-left" />
            </StyledLink>
        </>
    )
}

const StyledLink = styled(Link)`
    color: black;
    font-size: 40px;
`