import React from 'react'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'
import UserList from '../../../components/Admin/User/userlist'

const UserListPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide="true" content={
                <UserList />
            } />
        </>
    )
}

export default UserListPage
