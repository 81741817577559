import React, { useState } from 'react'
import {
    Subnavigationwrapper,
    LeftPart,
    RightPart,
    StyledDropdownToggle,
    CountryFlagImage,
    CountryNameText,
    SubnavigationItemWrapper,
    Divider
} from './SubnavigationElements'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import LineIcon from "react-lineicons"
import { useTranslation } from 'react-i18next'

const Subnavigation = () => {
    const { t, i18n } = useTranslation()

    const [languageValues, setLanguage] = useState({
        language: "Deutsch",
        languageshort: "de"
    })

    function changeLanguage(e) {
        const newLang = e.target.getAttribute("langshort")

        // set state
        setLanguage({ ...languageValues, language: e.target.innerText, languageshort: newLang })

        // update language
        i18n.changeLanguage(newLang);

        // update language in local storage
        localStorage.setItem("language", newLang)
    }

    return (
        <Subnavigationwrapper backgroundColor="#f8f9fa">
            <Container fluid="md">
                <LeftPart>
                    <Dropdown>
                        <StyledDropdownToggle variant="null">
                            <CountryFlagImage src={require("../../../images/icons/" + languageValues.languageshort + ".svg").default} alt="Deutsch" />
                            <CountryNameText>{languageValues.language}</CountryNameText>
                        </StyledDropdownToggle>

                        <Dropdown.Menu>
                            <LanguageItem lang="Deutsch" langshort="de" changeLang={changeLanguage} />
                            <LanguageItem disabled lang="Englisch" langshort="en" changeLang={changeLanguage} />
                        </Dropdown.Menu>
                    </Dropdown>
                </LeftPart>

                <RightPart>
                    <SubnavigationItemWrapper style={{"color": "gray"}} href="#" title={t("generally.coming_soon")}>
                        <LineIcon style={{ marginRight: "0.3rem", fontWeight: "700" }} name="search" />
                        {t("navigation.sub.looking-glass")}
                    </SubnavigationItemWrapper>
                    <Divider />
                    <SubnavigationItemWrapper href={process.env.REACT_APP_EXTERNAL_LINK_STATUSPAGE}>
                        <LineIcon style={{ marginRight: "0.3rem", fontWeight: "700" }} name="pulse" />
                        {t("navigation.sub.status")}
                    </SubnavigationItemWrapper>
                </RightPart>
            </Container>
        </Subnavigationwrapper>
    )
}

const LanguageItem = ({ disabled, langshort, lang, changeLang }) => {
    return (
        <>
            <Dropdown.Item disabled={disabled} langshort={langshort} onClick={changeLang}>
                <CountryFlagImage src={require("../../../images/icons/" + langshort + ".svg").default} alt="Deutsch" />
                {lang}
            </Dropdown.Item>
        </>
    )
}

export default Subnavigation
