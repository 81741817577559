import Button from "react-bootstrap/Button"
import styled from "styled-components"

export const HeaderWrapper = styled.div`
    background-image: linear-gradient(to right, var(--brand_color_primary), var(--brand_color_primary_lighter));
    padding-top: calc(1.8125rem + 6.75vw);
    padding-bottom: calc(1.8125rem + 6.75vw);

    @media (min-width: 1200px) {
        padding-top: 6.875rem;
    }
    @media (min-width: 1200px) {
        padding-bottom: 6.875rem;
    }
`

export const Title = styled.h1`
    margin: 0;
`

export const Features = styled.div`
    margin-top: calc(1.28125rem + 0.375vw);

    @media (min-width: 1200px) {
        margin-top: 1.5625rem;
    }
`

export const FeatureItem = styled.p`
    display: inline-block;
    margin: 8px 20px 0 0;
    font-weight: 500;
`

export const IconWrapper = styled.span`
    margin-right: 3px;
    color: white;
`

export const Promotion = styled.div`
    margin-top: calc(1.28125rem + 0.375vw);

    @media (min-width: 1200px) {
        margin-top: 1.5625rem;
    }
`

export const PromotionButtonFirst = styled(Button)`
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
`

export const PromotionButtonSecond = styled(Button)`
    margin-left: 10px;
    color: #f8f9fa;
    border-color: #f8f9fa;
`

export const InformationWrapper = styled.div`
    height: 40px;
    width: 100vw;
    background-color: var(--warning_information_background);
    font-size: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    font-weight: 700;
    border-top: 2px solid var(--warning_information_background_border)
`