import styled from "styled-components"
import Col from 'react-bootstrap/Col'
import { Heading as InfoHeading } from '../InfoSection/InfoElements'

export const ProductsWrapper = styled.div`
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    text-align: center;
    background-color: var(--color_background);
    padding-top: 80px;
    padding-bottom: 40px;
`

export const Title = styled.h4`
    text-transform: uppercase;
    font-family: "Lato-Bold", sans-serif;
`

export const Description = styled.p`
    font-size: 1rem;
    font-family: "Lato-Regular", sans-serif;
    font-weight: 100;
    margin: auto;
    max-width: 350px;
    margin-bottom: 20px;
`

export const CustomColumn = styled(Col)`
    margin-bottom: 60px;

    @media screen and (max-width: 480px) {
        margin-bottom: 80px;
    }
`

export const Icon = styled.div`
    font-size: calc(1.4375rem + 2.25vw);
    margin-bottom: 0.9375rem;
    color: #04A9A8 !important;
    background-color: var(--brand_color_primary);
    background-clip: text;
    -webkit-background-clip: text;

    @media (min-width: 1200px) {
        font-size: 3.125rem;
    }
`

export const Heading = styled(InfoHeading)`
    margin-bottom: 40px;
`