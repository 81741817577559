import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/Row'
import {
    ConfiguratorWrapper,
    Box
} from './ReponsiveBoxElements'
import Overlay from '../Overlay'

const ResponsiveBox = ({ miniHeight, wide, backgroundImg, boxComponent }) => {

    return (
        <>
            <ConfiguratorWrapper backgroundImg={backgroundImg}>

                <Overlay />

                <Container fluid="md">

                    <Row style={{ "justifyContent": "center" }}>

                        <Box miniheight={miniHeight} wide={wide}>

                            {boxComponent}

                        </Box>

                    </Row>

                </Container>

            </ConfiguratorWrapper>
        </>
    )
}

export default ResponsiveBox
