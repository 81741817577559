import React from 'react'
import InfoSite from '../components/InfoSite'
import Navigation from '../components/Navigation/Navigation'
import OrderList from '../components/Orders/orders'

const OrdersPage = () => {
    const backgroundImg = require("../images/products/kvm_server.jpg").default
    return (
        <>
            <Navigation />
            <InfoSite backgroundImg={backgroundImg} title={"pages.orders.orders"} content={<OrderList />} />
        </>
    )
}

export default OrdersPage
