import React from 'react'
import AdminCouponList from '../../../components/Admin/Coupons/couponlist'
import InfoSite from '../../../components/InfoSite'
import Navigation from '../../../components/Navigation/Navigation'

const AdminCouponListPage = () => {
    return (
        <>
            <Navigation />
            <InfoSite wide content={
                <AdminCouponList />
            } />
        </>
    )
}

export default AdminCouponListPage
