import { BackButton } from "../../custom/Backbutton"
import { Text } from "../../Forms/FormElements"
import ProductsList from "../../Products/productslist"

const AdminProductList = () => {

    return (
        <>
            <BackButton to="/adminpanel" />
            <h1>Produktverwaltung</h1>
            <Text>Alle Produkte:</Text>
            <ProductsList admin />
        </>
    )
}

export default AdminProductList