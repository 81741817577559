import React from 'react'
import RechargeCreditForm from '../Forms/recharge_credit'
import InfoSite from '../InfoSite'

const RechargeCredit = () => {
    const backgroundImg = require("../../images/products/kvm_server.jpg").default
    return (
        <>
            <InfoSite backgroundImg={backgroundImg} title={"pages.recharge_credit.recharge_credit"} content={<RechargeCreditForm />} />
        </>
    )
}

export default RechargeCredit
